// frontend/src/components/homepage/HomeTitleReveal/HomeTitleReveal.js
import React, { useRef, useEffect } from 'react';
import './HomeTitleReveal.css';
import TitleRevealVideo from '../../../assets/homepage/HomeTitleReveal/title-reveal.mp4';

const HomeTitleReveal = () => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const animationFrameIdRef = useRef(null);

  const handleScroll = () => {
    const video = videoRef.current;
    const container = containerRef.current;

    if (!video || !container) return;

    const rect = container.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const containerTopToWindowBottom = rect.top - windowHeight;
    const containerBottomToWindowTop = rect.bottom;
    const scrollRange = containerBottomToWindowTop - containerTopToWindowBottom;
    const scrollPosition = windowHeight - rect.top;

    if (scrollPosition >= 0 && scrollPosition <= scrollRange) {
      const scrollPercentage = scrollPosition / scrollRange;
      const videoTime = scrollPercentage * video.duration;

      if (!isNaN(videoTime)) {
        video.currentTime = videoTime;
      }
      
      // Pause the video if the user stops scrolling
      video.pause();
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    video.pause();
    video.currentTime = 0;

    const onScroll = () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
      animationFrameIdRef.current = requestAnimationFrame(handleScroll);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, []);

  return (
    <div className="home-title-reveal" ref={containerRef}>
      <video
        ref={videoRef}
        src={TitleRevealVideo}
        className="title-reveal-video"
        muted
        preload="auto"
        playsInline
      />
    </div>
  );
};

export default HomeTitleReveal;
