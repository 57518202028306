// frontend/src/components/MyJiraTicketSidebar.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Divider, List, Button, Spin, Alert, Modal, Card, Col, Row } from 'antd';
import { UnorderedListOutlined, ProjectOutlined, ClockCircleOutlined } from '@ant-design/icons';
import './MyJiraTicketSidebar.css';

const MyJiraTicketSidebar = ({ token }) => {
  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [error, setError] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBoardModalVisible, setIsBoardModalVisible] = useState(false);
  const [isBacklogModalVisible, setIsBacklogModalVisible] = useState(false);
  const [isTimelineModalVisible, setIsTimelineModalVisible] = useState(false);
  const [boardData, setBoardData] = useState(null);
  const [backlogData, setBacklogData] = useState(null);
  const [timelineData, setTimelineData] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      console.log('Fetching Jira projects with token:', token);
      setLoadingProjects(true);
      setError(null);
      try {
        const response = await axios.get(`/jira/projects`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log('Fetched Jira projects:', response.data);
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Failed to fetch Jira projects.');
      } finally {
        setLoadingProjects(false);
      }
    };

    if (token) {
      fetchProjects();
    }
  }, [token]);

  const handleProjectSelect = async (projectKey) => {
    setSelectedProject(projectKey);
    console.log('Fetching Jira tickets for project:', projectKey);
    setLoadingTickets(true);
    setError(null);
    try {
      const response = await axios.get(`/jira/tickets/${projectKey}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('Fetched Jira tickets:', response.data);
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching tickets in MyJiraTicketSidebar:', error);
      setError('Failed to fetch Jira tickets.');
    } finally {
      setLoadingTickets(false);
    }
  };

  const viewTicketDetails = async (ticketId) => {
    setIsModalVisible(true);
    try {
      const response = await axios.get(`/jira/tickets/details/${ticketId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTicketDetails(response.data);
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      setError('Failed to load ticket details.');
    }
  };

  const fetchBoardData = async () => {
    setIsBoardModalVisible(true);
    try {
      const response = await axios.get(`/jira/board/${selectedProject}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBoardData(response.data);
    } catch (error) {
      setError('Failed to load board data.');
    }
  };

  const fetchBacklogData = async () => {
    setIsBacklogModalVisible(true);
    try {
      const response = await axios.get(`/jira/backlog/${selectedProject}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBacklogData(response.data);
    } catch (error) {
      setError('Failed to load backlog data.');
    }
  };

  const fetchTimelineData = async () => {
    setIsTimelineModalVisible(true);
    try {
      const response = await axios.get(`/jira/timeline/${selectedProject}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTimelineData(response.data);
    } catch (error) {
      setError('Failed to load timeline data.');
    }
  };

  //const openBoardModal = () => setIsBoardModalVisible(true);
  //const openBacklogModal = () => setIsBacklogModalVisible(true);
  //const openTimelineModal = () => setIsTimelineModalVisible(true);

  return (
    <div className="JiraSidebaruWrap">
      {error && <Alert message={error} type="error" showIcon closable />}
      {loadingProjects ? (
        <Spin tip="Loading projects..." />
      ) : (
        <Select
          placeholder="Select a project"
          onChange={handleProjectSelect}
          style={{ width: '100%' }}
        >
          {projects.map((project) => (
            <Select.Option key={project.key} value={project.key}>
              {project.name}
            </Select.Option>
          ))}
        </Select>
      )}

      {/* Icon Buttons for Board, Backlog, and Timeline */}
      <div className="JiraViewButtons">
        <Button icon={<ProjectOutlined />} onClick={fetchBoardData}>Board</Button>
        <Button icon={<UnorderedListOutlined />} onClick={fetchBacklogData}>Backlog</Button>
      </div>
      {loadingTickets ? (
        <Spin tip="Loading tickets..." />
      ) : (
        <List
          itemLayout="vertical"
          dataSource={tickets}
          renderItem={(ticket) => (
            <List.Item key={ticket.id}>
              <List.Item.Meta title={ticket.fields.summary} description={`Issue Key: ${ticket.key}`} />
              <Button onClick={() => viewTicketDetails(ticket.id)}>View Details</Button>
            </List.Item>
          )}
        />
      )}

      {/* Modal for Ticket Details */}
      <Modal
        title={ticketDetails?.fields?.summary || 'Ticket Details'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {ticketDetails ? (
          <div>
            <p><strong>Description:</strong> {ticketDetails.fields.description?.content[0]?.content[0]?.text || 'No description available'}</p>
            <p><strong>Status:</strong> {ticketDetails.fields.status?.name}</p>
            <p><strong>Assignee:</strong> {ticketDetails.fields.assignee?.displayName || 'Unassigned'}</p>
            <p><strong>Priority:</strong> {ticketDetails.fields.priority?.name}</p>
            <p><strong>Created:</strong> {ticketDetails.fields.created}</p>
            <p><strong>Updated:</strong> {ticketDetails.fields.updated}</p>
            {/* Add more fields as needed */}
          </div>
        ) : (
          <Spin tip="Loading ticket details..." />
        )}
      </Modal>
      {/* Modal for Board */}
      <Modal
        title="Jira Project Board"
        visible={isBoardModalVisible}
        onCancel={() => setIsBoardModalVisible(false)}
        footer={null}
      >
        {boardData ? (
          <Row gutter={16}>
            {boardData.columns.map((column, index) => (
              <Col span={8} key={index}>
                <Card title={column.columnName} bordered={false}>
                  <List
                    itemLayout="vertical"
                    dataSource={column.tickets}
                    renderItem={(ticket) => (
                      <List.Item key={ticket.id}>
                        <List.Item.Meta
                          title={ticket.fields.summary}
                          description={`Status: ${ticket.fields.status.name}`}
                        />
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Spin tip="Loading board data..." />
        )}
      </Modal>
      {/* Modal for Backlog */}
      <Modal
        title="Jira Project Backlog"
        visible={isBacklogModalVisible}
        onCancel={() => setIsBacklogModalVisible(false)}
        footer={null}
      >
        {backlogData ? <pre>{JSON.stringify(backlogData, null, 2)}</pre> : <Spin tip="Loading backlog data..." />}
      </Modal>
      {/* Modal for Project Timeline */}
      <Modal
        title="Jira Project Timeline"
        visible={isTimelineModalVisible}
        onCancel={() => setIsTimelineModalVisible(false)}
        footer={null}
      >
        {timelineData ? <pre>{JSON.stringify(timelineData, null, 2)}</pre> : <Spin tip="Loading timeline data..." />}
      </Modal>
    </div>
  );
};

export default MyJiraTicketSidebar;
