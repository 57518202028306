// frontend/src/components/general/VerticalScrollIndicator/VerticalScrollIndicator.js
import React, { useEffect } from 'react';
import './VerticalScrollIndicator.css';

const VerticalScrollIndicator = () => {
  useEffect(() => {
    const animateScrollIndicator = () => {
      const indicator = document.querySelector('.indicator');
      indicator.style.animation = 'scrollIndicatorAnimation 3s infinite ease-in-out';
    };

    animateScrollIndicator();
  }, []);

  return (
    <div className="scroll-indicator-wrapper">
      <div className="scroll-indicator">
        <div className="indicator"></div>
      </div>
    </div>
  );
};

export default VerticalScrollIndicator;
