// frontend/src/components/codeEditor/MenuBar.js
import React from 'react';
import { Menu } from 'antd';
import { SaveOutlined, CopyOutlined, UndoOutlined, RedoOutlined, SearchOutlined, ReloadOutlined, SplitCellsOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

const MenuBar = ({ onSave, onSaveComponent, onCopy, onUndo, onRedo, onSearch, onWrapUnwrap, isWrapped, onSplitView }) => {
  return (
    <Menu mode="horizontal">
      <SubMenu key="file" title="File">
        <Menu.Item key="savePage" icon={<SaveOutlined />} onClick={onSave}>Save Page</Menu.Item>
        <Menu.Item key="saveComponent" icon={<SaveOutlined />} onClick={onSaveComponent}>Save Component</Menu.Item>
      </SubMenu>
      <SubMenu key="edit" title="Edit">
        <Menu.Item key="copy" icon={<CopyOutlined />} onClick={onCopy}>Copy</Menu.Item>
        <Menu.Item key="undo" icon={<UndoOutlined />} onClick={onUndo}>Undo</Menu.Item>
        <Menu.Item key="redo" icon={<RedoOutlined />} onClick={onRedo}>Redo</Menu.Item>
        <Menu.Item key="search" icon={<SearchOutlined />} onClick={onSearch}>Search</Menu.Item>
      </SubMenu>
      <SubMenu key="view" title="View">
        <Menu.Item key="wrap" icon={<ReloadOutlined />} onClick={onWrapUnwrap}>
          {isWrapped ? 'Unwrap' : 'Wrap'}
        </Menu.Item>
        <Menu.Item key="split" icon={<SplitCellsOutlined />} onClick={onSplitView}>
          Split View
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default MenuBar;
