// frontend/src/components/ui_components/AddNestedComponent.jsx
import React, { useState, useContext, useEffect } from 'react';
import { NestedComponentsContext } from '../../contexts/NestedComponentsContext';
import { v4 as uuidv4 } from 'uuid';
import { components as componentImports } from '../ComponentImports';
import { loadComponentFromS3, registerDynamicComponent } from '../DynamicComponentLoader';
import { getComponentProps } from '../../hooks/getComponentProps';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import axios from 'axios';

const { Option } = Select;

const AddNestedComponent = ({
  containerId,
  tokens,
  designTokens,
  editedTokens
}) => {
  const [selectedComponent, setSelectedComponent] = useState('');
  const { addNestedComponent } = useContext(NestedComponentsContext);
  const [localComponents, setLocalComponents] = useState([]);
  const [s3Components, setS3Components] = useState([]);
  const [allComponents, setAllComponents] = useState([]);

  // Retrieve token from localStorage
  const token = localStorage.getItem('token') || '';

  useEffect(() => {
    // Fetch S3 components
    const fetchS3Components = async () => {
      try {
        const response = await axios.get('https://www.webbify.io/component-management/list', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Fetched S3 components:', response.data.components);
        setS3Components(response.data.components);
      } catch (error) {
        console.error('Error fetching S3 components:', error);
      }
    };

    fetchS3Components();

    // Set local components
    setLocalComponents(Object.keys(componentImports));
  }, [token]);

  useEffect(() => {
    // Combine local and S3 components
    const combinedComponents = [
      ...localComponents.map((name) => ({ name, source: 'local' })),
      ...s3Components.map((component) => ({ name: component.key, source: 's3' })),
    ];
    console.log('Combined Components:', combinedComponents); // Debug log
    setAllComponents(combinedComponents);
  }, [localComponents, s3Components]);

  const handleAddComponent = async () => {
    const selectedComponentObj = allComponents.find(
      (component) => component.name === selectedComponent
    );

    if (!selectedComponentObj) {
      console.error('Selected component not found');
      return;
    }

    let ComponentModule;
    
    if (selectedComponentObj.source === 's3') {
      // Load S3 component
      console.log(`Loading S3 component: ${selectedComponentObj.name}`);
      const Component = await loadComponentFromS3(selectedComponentObj.name, token);
      if (Component) {
        // Register the component dynamically
        registerDynamicComponent(componentImports, selectedComponentObj.name, Component);
        ComponentModule = Component;
      } else {
        console.error(`Failed to load S3 component: ${selectedComponentObj.name}`);
        return;
      }
    } else if (selectedComponentObj.source === 'local') {
      // Load local component
      const ComponentImportFunction = componentImports[selectedComponentObj.name];
      if (!ComponentImportFunction || typeof ComponentImportFunction !== 'function') {
        console.error(`Component import function not found or invalid for: ${selectedComponentObj.name}`);
        return;
      }
      try {
        const module = await ComponentImportFunction();
        const Component = module.default || module;
        if (!Component) {
          console.error(`Component module not resolved correctly for: ${selectedComponentObj.name}`);
          return;
        }
        ComponentModule = Component;
      } catch (error) {
        console.error('Error loading local component:', error);
        return;
      }
    } else {
      console.error('Unknown component source:', selectedComponentObj.source);
      return;
    }

    if (!ComponentModule) {
      console.error(`Failed to resolve component module for: ${selectedComponent}`);
      return;
    }

    // Handle adding the container component with simpler approach
    if (selectedComponentObj.name === 'Container') {
      addNestedComponent(containerId, 0, {
        id: uuidv4(),
        component: ComponentModule,
        name: selectedComponentObj.name,
        source: selectedComponentObj.source,
        s3ComponentName: selectedComponentObj.source === 's3' ? selectedComponentObj.name : undefined,
        props: getComponentProps(ComponentModule),
        tokens: { ...designTokens[selectedComponentObj.name], ...editedTokens[selectedComponentObj.name], ...tokens },
      });
      return;
    }

    const initialProps = getComponentProps(ComponentModule);
    const newComponentTokens = {
      ...designTokens[selectedComponentObj.name],
      ...editedTokens[selectedComponentObj.name],
      ...tokens,
    };

    const newComponent = {
      id: uuidv4(),
      component: ComponentModule,
      name: selectedComponentObj.name,
      source: selectedComponentObj.source,
      s3ComponentName: selectedComponentObj.source === 's3' ? selectedComponentObj.name : undefined,
      props: initialProps,
      tokens: newComponentTokens,
    };

    console.log('Adding new component:', newComponent);

    addNestedComponent(containerId, 0, newComponent);
  };

  return (
    <div className="selectionPanel-AddNestedContainer">
      <div className="SelectionPanel-content">
        <h2>Add a component</h2>
        <p>Select a component to add into the container</p>
      </div>
      <div className="SelectionPanel-controls">
        <Select
          placeholder="Select component"
          className="selectionPanel-AddNestedSelect"
          value={selectedComponent}
          onChange={(value) => setSelectedComponent(value)} // Update to get value directly
        >
          {allComponents.map((component) => (
            <Option key={`${component.source}-${component.name}`} value={component.name}>
              {component.name} ({component.source})
            </Option>
          ))}
        </Select>
        <Button
          className="selectionPanel-AddnestedButton"
          onClick={handleAddComponent}
          disabled={!selectedComponent}
        >
          <PlusOutlined />
        </Button>
      </div>
    </div>
  );
};

export default AddNestedComponent;
