// frontend/src/pages/CodeEditor.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Layout, Button, message } from 'antd';
import { CopyOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { ReactComponent as DragIcon } from '../assets/icons/drag-icon.svg';
import { ReactComponent as JiraIcon } from '../assets/icons/jira-icon.svg';

import '../codeEditor.css';
import Header from '../components/Header';
import '../meAInterface.css';
import CodeEditorFilesSidebar from '../components/codeEditor/CodeEditorFilesSidebar';
import RightSidebar from '../components/RightSidebar';
import { fetchPageContent } from '../utils/fetchPageContentUtils';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import MonacoEditor from '../components/MonacoEditor';
//import CodeMirrorEditor from '../components/CodeMirrorEditor';
import MenuBar from '../components/codeEditor/MenuBar';
import CodeEditorComponentSidebar from '../components/codeEditor/CodeEditorComponentSidebar';
import NewComponentTemplate from '../utils/templates/NewComponentTemplate';

const { Content } = Layout;

const CodeEditor = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(false);
  //const [leftCode, setLeftCode] = useState({ code: '', lineWrapping: true, mode: 'application/json' });
  const [leftCode, setLeftCode] = useState({ code: '', language: 'json' });
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const userId = localStorage.getItem('userId') || '';
  const [activePage, setActivePage] = useState(null);
  const [activePageId, setActivePageId] = useState(null);
  const [pages, setPages] = useState([]);
  const [pageContents, setPageContents] = useState({});
  const [switchLeftSidebar, setSwitchLeftSidebar] = useState(true);

  const [components, setComponents] = useState([]);
  const [designTokens, setDesignTokens] = useState({});
  const [editedTokens, setEditedTokens] = useState({});
  const [nestedComponents, setNestedComponents] = useState({});
  const [selectedComponentInfo, setSelectedComponentInfo] = useState({});
  const [componentCode, setComponentCode] = useState('');

  const [initialHeight, setInitialHeight] = useState(0);
  const editorContainerRef = useRef(null);

  const editorRef = useRef(null);

  const { convertFrontendStructureToBackendSchema } = usePageContentUtils();

  useEffect(() => {
    if (editorContainerRef.current) {
      // Set initial height in state and CSS variable
      const calculatedHeight = editorContainerRef.current.clientHeight;
      setInitialHeight(calculatedHeight);
      document.documentElement.style.setProperty('--initial-editor-height', `${calculatedHeight}px`);
    }
  }, []);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const pagesData = await fetchPageContent(token, {}, {}, {}, () => {}, {}, () => {}, {});
        if (pagesData) {
          setPages(pagesData.map(page => ({ pageName: page.pageName, pageId: page.pageId })));
          const pageContentsMap = pagesData.reduce((acc, page) => {
            acc[page.pageName] = page.content;
            return acc;
          }, {});
          setPageContents(pageContentsMap);
        }
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };

    fetchPages();
  }, [token]);

  useEffect(() => {
    async function fetchComponents() {
      try {
        const response = await axios.get('https://www.webbify.io/components', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data) {
          setComponents(response.data); // Assuming response.data is an array of component objects
        }
      } catch (error) {
        console.error('Failed to fetch components:', error);
        message.error('Failed to fetch components');
      }
    }

    fetchComponents();
  }, [token]);

  const handleRightSidebarToggle = () => {
    setIsRightSidebarCollapsed(prevValue => !prevValue);
  };

  const handleLeftCodeChange = value => {
    setLeftCode({ ...leftCode, code: value });
  };

  const handleCopy = () => {
    editorRef.current.handleCopy();
  };

  const handleWrapUnwrap = () => {
    // Monaco Editor handles word wrap via options, so you might want to toggle it here
    // Implement as needed
    // For simplicity, we're not implementing it here
    message.info('Word wrap toggling is not implemented yet.');
  };
  //const handleWrapUnwrap = () => {
  //  setLeftCode(prevCode => ({
  //    ...prevCode,
  //    lineWrapping: !prevCode.lineWrapping,
  //  }));
  //};

  const handleSelectPage = pageName => {
    const selectedPage = pages.find(page => page.pageName === pageName);
    setActivePage(pageName);
    setActivePageId(selectedPage.pageId); // Store the active pageId
    const pageContent = pageContents[pageName];
    setLeftCode({
      code: JSON.stringify(pageContent, null, 2), // Pretty print the JSON content
      language: 'json'
    });
  };

  // Save Pages-Files to MongoDB
  const handleSave = async () => {
    if (!activePage || !activePageId) {
      message.error('No active page to save');
      return;
    }

    try {
      const updatedContent = JSON.parse(leftCode.code);
      const backendSchema = convertFrontendStructureToBackendSchema(updatedContent.props.rows || updatedContent, nestedComponents);

      await axios.put(
        'https://www.webbify.io/pageContent',
        {
          userId: userId,
          pageId: activePageId, // Use the activePageId
          pageName: activePage,
          content: { rows: backendSchema },
          designTokens,
          editedTokens,
          nestedComponents
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Page content saved successfully');
    } catch (error) {
      message.error('Failed to save page content. Invalid JSON.');
      console.error('Failed to parse JSON or save content:', error);
    }
  };

  // Save Component to MongoDB
  const saveComponentCode = async () => {
    if (!leftCode.code || !selectedComponentInfo.name) {
      message.error('No component code or name specified');
      return;
    }

    const apiUrl = 'https://www.webbify.io/components';
    const headers = { Authorization: `Bearer ${token}` };
    const componentData = {
      name: selectedComponentInfo.name,
      code: leftCode.code,
      userId: userId
    };

    try {
      let response;
      if (selectedComponentInfo.id) {
        // If the component has an ID, update it
        response = await axios.put(`${apiUrl}/${selectedComponentInfo.id}`, componentData, { headers });
        // Update local state with the new component data
        const updatedComponents = components.map(comp =>
          comp._id === selectedComponentInfo.id ? { ...comp, code: leftCode.code } : comp
        );
        setComponents(updatedComponents);
      } else {
        // No ID means this is a new component
        response = await axios.post(apiUrl, componentData, { headers });
        // Add the new component to the local state
        setComponents([...components, { ...componentData, _id: response.data._id }]);
      }
      message.success('Component saved successfully!');
      setSelectedComponentInfo(prev => ({ ...prev, code: leftCode.code }));
    } catch (error) {
      console.error('Failed to save component:', error);
      message.error(`Failed to save component: ${error.response.data.message}`);
    }
  };

  const handleSwitchSidebar = (sidebar) => {
    setSwitchLeftSidebar(sidebar === 'pages');
  };

  const handleComponentSelect = (item) => {
    setSelectedComponentInfo({ name: item.name, code: item.code, id: item._id }); // Ensure 'id' is captured
    setLeftCode({ code: item.code, language: 'javascript' });
  };

  const handleCreateComponent = async (componentName) => {
    const apiUrl = 'https://www.webbify.io/components';
    const componentData = {
      name: componentName,
      code: NewComponentTemplate, // Use the default template
      userId: userId
    };

    try {
      const response = await axios.post(apiUrl, componentData, {
          headers: { Authorization: `Bearer ${token}` }
      });
      // Refresh the component list to include the new component
      const newComponent = { ...response.data, code: NewComponentTemplate };
      setComponents(prevComponents => [...prevComponents, newComponent]);
      setSelectedComponentInfo({ name: componentName, code: NewComponentTemplate, id: newComponent._id });
      setLeftCode({ code: NewComponentTemplate, language: 'javascript' });
      message.success('Component created successfully!');
    } catch (error) {
      console.error('Failed to create component:', error);
      message.error('Failed to create component: ' + error.response?.data.message);
    }
  };

  return (
    <div className="codeEditor">
      <Header className="mini-main-header" isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="codeEditor-container">
        <div className="cEditLeftSidebar">
          <div className="LeftSideBarTopControls">
            <div className="DragHandler">
              <DragIcon />
            </div>
            <button onClick={() => handleSwitchSidebar('pages')}
            className={!switchLeftSidebar ? 'active' : ''}
            >Pages</button>
            <button onClick={() => handleSwitchSidebar('components')}
            className={!switchLeftSidebar ? 'active' : ''}
            >Components</button>
          </div>
          {switchLeftSidebar ? (
            <CodeEditorFilesSidebar
              pages={pages.map(page => page.pageName)}
              activePage={activePage}
              onSelectPage={handleSelectPage}
            />
          ) : (
            <CodeEditorComponentSidebar 
              onSelectComponent={handleComponentSelect} 
              savedComponents={components} 
              onCreateComponent={handleCreateComponent} 
            />
          )}
        </div>

        {/* Code Container */}
        <div className="CodeEditorMain" ref={editorContainerRef}>
          <div className="code-editor-top-bar">
            <MenuBar
              onSave={handleSave}
              onSaveComponent={saveComponentCode}
              onCopy={handleCopy}
              onUndo={() => editorRef.current.handleUndo()}
              onRedo={() => editorRef.current.handleRedo()}
              onSearch={() => editorRef.current.handleSearch()}
              onWrapUnwrap={handleWrapUnwrap}
              isWrapped={leftCode.lineWrapping}
              onSplitView={() => editorRef.current.toggleSplitView()}
            />
          </div>
          <div className="CodeMirrorContainer">
            <MonacoEditor
              ref={editorRef}
              value={leftCode.code}
              onChange={handleLeftCodeChange}
              language={leftCode.language}
            />
          </div>
        </div>
        <div className="cEditRightSidebar">
          <div className="RightSideBarTopControls">
            <div className="DragHandler">
              <DragIcon />
            </div>
            <button>Ai Code</button>
            <button className="temp-disabled">Ai Image</button>
            <button className="temp-disabled"><JiraIcon />Jira(5)</button>
          </div>
          {/* Right Sidebar */}
          <RightSidebar />
        </div>
      </div>
    </div>
  );
};

export default CodeEditor;
