// frontend/src/hooks/useSavePageContainer.js
import axios from 'axios';

const useSavePageContainer = () => {
  const savePageContainer = async (token, userId, filename, content) => {
    try {
      const response = await axios.post('/files/savePageContainer', {
        filename,
        content,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('PageContainer content saved successfully:', response.data.url);
        return response.data.url;
      } else {
        console.error('Failed to save PageContainer content');
        return null;
      }
    } catch (error) {
      console.error('Error saving PageContainer content:', error);
      return null;
    }
  };

  return { savePageContainer };
};

export default useSavePageContainer;
