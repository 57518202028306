// frontend/src/components/SelectionPanel.js
import React, { useContext, useState, useEffect } from 'react';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import Draggable from 'react-draggable'; 
import { Switch, Collapse, Select, Spin, Input, Button } from 'antd';
import axios from 'axios';
import AddNestedComponent from './ui_components/AddNestedComponent';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';
import { TokensContext } from '../contexts/TokensContext';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const SelectionPanel = ({ onClose }) => {
  const { designTokens, editedTokens, tokens } = useContext(TokensContext);
  const { selectedComponentInfo } = useContext(SelectedComponentContext);
  const { updateComponentProps } = useContext(ComponentPropsContext);
  const [activeTab, setActiveTab] = useState('Visual Editor');
  const [localProps, setLocalProps] = useState(selectedComponentInfo?.props || {});
  const [colorValue, setColorValue] = useState(null);
  const [tokenNames, setTokenNames] = useState({});
  // images items
  const [loading, setLoading] = useState(false);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(selectedComponentInfo?.props?.backgroundImage || '');
  const [fillOption, setFillOption] = useState(localProps.fill || 'cover');
  // Type items
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [textValue, setTextValue] = useState(selectedComponentInfo?.props?.content || '');

  useEffect(() => {
    console.log('SelectionPanel - Selected Component Info:', selectedComponentInfo);
    setLocalProps(selectedComponentInfo?.props || {});
    setBackgroundImage(selectedComponentInfo?.props?.backgroundImage || '');
    setFillOption(selectedComponentInfo?.props?.fill || 'cover');
    if ('src' in (selectedComponentInfo?.props || {})) {
      fetchImages();
    }
  }, [selectedComponentInfo]);

  useEffect(() => {
    if (selectedComponentInfo?.name === 'Container') {
      fetchImages(); // Fetch images if Container is selected
    }
  }, [selectedComponentInfo]);

  // Function to fetch images from S3
  const fetchImages = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/media/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { fileType: 'Images' },
      });
      const images = response.data.files.map(file => ({
        key: file.key,
        name: file.key.split('/').pop(),
        url: `https://webbifymedia.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${file.key}`,
      }));
      setMediaOptions(images);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePropChange = (propName, newValue) => {
    console.log(`SelectionPanel - Updating prop ${propName} to ${newValue}`);
    updateComponentProps(selectedComponentInfo.id, { [propName]: newValue });
  };

  const handleImageChange = (value) => {
    const selectedImage = mediaOptions.find(img => img.key === value);
    if (selectedImage) {
      handlePropChange('src', selectedImage.url);
    }
  };

  const handleBackgroundImageChange = (value) => {
    const selectedImage = mediaOptions.find((img) => img.key === value);
    if (selectedImage) {
      updateComponentProps(selectedComponentInfo.id, { backgroundImage: selectedImage.url });
      setBackgroundImage(selectedImage.url);
    }
  };

  const handleFillChange = (value) => {
    setFillOption(value);
    handlePropChange('fill', value);
  };

  const handleInputBlur = (propName, value) => {
    handlePropChange(propName, value);
  };

  // Text component items
  useEffect(() => {
    setTextValue(selectedComponentInfo?.props?.content || '');
  }, [selectedComponentInfo]);

  const handleContentChange = (newValue) => {
    setTextValue(newValue);
    updateComponentProps(selectedComponentInfo.id, { content: newValue });
  };

  const handleFormattingChange = (formatType) => {
    let updatedContent = localProps.content;
    if (formatType === 'bold') setIsBold(!isBold);
    if (formatType === 'italic') setIsItalic(!isItalic);
    // Update styling based on toggles
    updateComponentProps(selectedComponentInfo.id, {
      tokens: {
        ...localProps.tokens,
        fontWeight: isBold ? 'bold' : 'normal',
        fontStyle: isItalic ? 'italic' : 'normal',
      },
    });
  };

  return (
    <Draggable bounds="parent">
      <div className="selection-panel">
        <header>
          <h4>Selected component: {selectedComponentInfo?.name}</h4>
          <button onClick={onClose}>Close</button>
        </header>
        <div className="tabs">
          <button onClick={() => setActiveTab('Visual Editor')} className={activeTab === 'Visual Editor' ? 'active' : ''}>
            Visual Editor
          </button>
          <button onClick={() => setActiveTab('Empty Tab')} className={activeTab === 'Empty Tab' ? 'active' : ''}>
            Empty Tab Button
          </button>
        </div>
        {activeTab === 'Visual Editor' && (
          <div className="visual-editor">
            {Object.entries(localProps).map(([propName, propValue]) => {
              //if (selectedComponentInfo.name === 'Image') {
              // Conditional logic for `src` and `fill` based on presence of these props
              if (propName === 'src' || propName === 'fill') {
                if (propName === 'src') return null;
                if (propName === 'fill') {
                  return (
                    <div key={propName}>
                      <label>{propName.charAt(0).toUpperCase() + propName.slice(1)}</label>
                      <Select
                        value={fillOption}
                        onChange={handleFillChange}
                        style={{ width: '100%' }}
                      >
                        <Option value="cover">Cover</Option>
                        <Option value="contain">Contain</Option>
                        <Option value="fill">Fill</Option>
                        <Option value="scale-down">Scale Down</Option>
                        <Option value="none">None</Option>
                      </Select>
                    </div>
                  );
                }
              }

              // Render other props
              return (
                <div key={propName}>
                  <label>{propName.charAt(0).toUpperCase() + propName.slice(1)}</label>
                  {typeof propValue === 'boolean' ? (
                    <Switch
                      checked={propValue}
                      onChange={(checked) => handlePropChange(propName, checked)}
                    />
                  ) : typeof propValue === 'string' && propName === 'variant' ? (
                    <Select
                      value={propValue}
                      onChange={(e) => handlePropChange(propName, e.target.value)}
                    >
                      {Object.keys(selectedComponentInfo.props?.variants || {}).map((variantKey) => (
                        <Option key={variantKey} value={variantKey}>
                          {variantKey.charAt(0).toUpperCase() + variantKey.slice(1)}
                        </Option>
                      ))}
                    </Select>
                  ) : propName === 'content' ? (
                    <input
                      value={propValue}
                      onChange={(e) => setLocalProps((prev) => ({ ...prev, [propName]: e.target.value }))}
                      onBlur={() => handleInputBlur(propName, propValue)}
                    />
                  ) : (
                    <input
                      value={propValue}
                      onChange={(e) => setLocalProps((prev) => ({ ...prev, [propName]: e.target.value }))}
                      onBlur={() => handleInputBlur(propName, propValue)}
                    />
                  )}
                </div>
              );
            })}

            {'src' in localProps && (
              <div>
                <label>Select Image Source</label>
                {loading ? (
                  <Spin />
                ) : (
                  <Select
                    showSearch
                    placeholder="Select an image"
                    optionFilterProp="children"
                    onChange={handleImageChange}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: '100%' }}
                  >
                    {mediaOptions.map(image => (
                      <Option key={image.key} value={image.key}>
                        {image.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
            )}

            {/* Conditionally render ContainerControls if the selected component is a Container */}
            {selectedComponentInfo?.name === 'Container' && (
              <>
                <AddNestedComponent
                  containerId={selectedComponentInfo.id}
                  tokens={localProps.tokens || {}}
                  designTokens={designTokens}  // Pass actual design tokens if needed
                  editedTokens={editedTokens}  // Pass actual edited tokens if needed
                />
                <div className="SelectPanelImageSelector">
                  <label>Select Background Image</label>
                  {loading ? (
                    <Spin />
                  ) : (
                    <Select
                      showSearch
                      placeholder="Select an image"
                      optionFilterProp="children"
                      onChange={handleBackgroundImageChange}
                      value={backgroundImage}
                      style={{ width: '100%' }}
                    >
                      {mediaOptions.map((image) => (
                        <Option key={image.key} value={image.key}>
                          {image.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                  <label>Background Image Cover Style</label>
                  <Select
                    value={fillOption}
                    onChange={handleFillChange}
                    style={{ width: '100%' }}
                  >
                    <Option value="cover">Cover</Option>
                    <Option value="contain">Contain</Option>
                    <Option value="fill">Fill</Option>
                    <Option value="scale-down">Scale Down</Option>
                    <Option value="none">None</Option>
                  </Select>
                </div>
              </>
            )}

            {/* Text Content Editor */}
            {selectedComponentInfo?.name === 'Text' && (
              <div>
                <label>Text Content</label>
                <TextArea
                  value={localProps.content}
                  onChange={(e) => handleContentChange(e.target.value)}
                  rows="4"
                  style={{ width: '100%' }}                  
                />
                <Button onClick={() => handleFormattingChange('bold')} style={{ marginRight: '8px' }}>
                  Bold
                </Button>
                <Button onClick={() => handleFormattingChange('italic')}>
                  Italic
                </Button>
              </div>
            )}
            {/* Render other props */}
            {Object.entries(localProps).map(([propName, propValue]) => (
              propName !== 'content' && (
                <div key={propName}>
                  <label>{propName.charAt(0).toUpperCase() + propName.slice(1)}</label>
                  <Switch
                    checked={propValue}
                    onChange={(checked) => updateComponentProps(selectedComponentInfo.id, { [propName]: checked })}
                  />
                </div>
              )
            ))}

          </div>
        )}
        {activeTab === 'Empty Tab' && (
          <div className="empty-tab">
            {/* Empty tab content */}
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default SelectionPanel;
