// frontend/src/contexts/SelectedComponentContext.js

import React, { createContext, useState } from 'react';

export const SelectedComponentContext = createContext();

export const SelectedComponentProvider = ({ children }) => {
  const [selectedComponentInfo, setSelectedComponentInfo] = useState(null);

  return (
    <SelectedComponentContext.Provider value={{ selectedComponentInfo, setSelectedComponentInfo }}>
      {children}
    </SelectedComponentContext.Provider>
  );
};
