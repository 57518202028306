// frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Expose React and ReactDOM globally
window.React = React;
window.ReactDOM = ReactDOM;

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Parse the URL to check for userurl
const pathParts = window.location.pathname.split('/').filter(part => part !== '');
const [userId, userurl, pageName] = pathParts;

if (userId && userurl && !['dashboard', 'uibuilder', 'media', 'CodeEditor'].includes(userId)) {
  // This is a public user page, not an app route
  // Render a PublicApp component
  import('./PublicApp').then(({ default: PublicApp }) => {
    root.render(<PublicApp userId={userId} userurl={userurl} pageName={pageName} />);
  });
} else {
  // Render the regular App
  root.render(<App />);
}