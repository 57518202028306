// frontend/src/components/CodeEditorComponentSidebar.js
import React, { useState, useEffect } from 'react';
import { List, Collapse, Input, Button, message } from 'antd';
import { componentCodeImports } from '../ComponentCodeImports';

const { Panel } = Collapse;

const CodeEditorComponentSidebar = ({ onSelectComponent, savedComponents, onCreateComponent }) => {
  const [componentsList, setComponentsList] = useState([]);
  const [userComponents, setUserComponents] = useState([]);
  const [newComponentName, setNewComponentName] = useState('');
  const [activeComponent, setActiveComponent] = useState(null);

  useEffect(() => {
	  async function loadComponentCodes() {
	    const defaultComponents = await Promise.all(
	      Object.entries(componentCodeImports).map(async ([name, loader]) => {
	        const code = await loader();
	        return { name, code, type: 'default' };
	      })
	    );

	    const userSpecificComponents = savedComponents.map(comp => ({
	      ...comp,
	      type: 'user'
	    }));

	    setComponentsList(defaultComponents);
	    setUserComponents(userSpecificComponents);
	  }

	  loadComponentCodes();
	}, [savedComponents]);

  const handleCreateComponent = async () => {
	  if (newComponentName) {
	    onCreateComponent(newComponentName);  // This should call the prop function
	    setNewComponentName('');  // Reset input after creation
	  } else {
	    message.error('Component name cannot be empty');
	  }
	};

	const handleComponentSelect = (item) => {
    setActiveComponent(item.name); // Set the active component
    onSelectComponent(item); // Trigger the parent handler
  };

  return (
  	<div className="codeComponentSidebar">
  		<div className="files-sidebar-controls">
  			<Input
  				type="text"
          placeholder="Component name"
          value={newComponentName}
          onChange={e => setNewComponentName(e.target.value)}
        />
        <button onClick={handleCreateComponent}>
          Create
        </button>
      </div>
	    <Collapse className="sidebar-collapse-wrapper" defaultActiveKey={['1', '2']} accordion>
	      <Panel header="Default Components" key="1">
	        <List
	          itemLayout="horizontal"
	          dataSource={componentsList}
	          renderItem={item => (
	            <List.Item className={activeComponent === item.name ? 'active' : ''} onClick={() => handleComponentSelect(item)}>
	              <List.Item.Meta title={item.name} />
	            </List.Item>
	          )}
	        />
	      </Panel>
	      <Panel header="User Components" key="2">
	        <List
	          itemLayout="horizontal"
	          dataSource={userComponents}
	          renderItem={item => (
	            <List.Item className={activeComponent === item.name ? 'active' : ''} onClick={() => handleComponentSelect(item)}>
	              <List.Item.Meta title={item.name} />
	            </List.Item>
	          )}
	        />
	      </Panel>
	    </Collapse>
	  </div>
  );
};

export default CodeEditorComponentSidebar;
