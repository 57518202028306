// frontend/src/components/properties-sidebar/ColorOptionsHelper.js

import React from 'react';
import { Dropdown, Button, Menu, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getRelevantTokens } from './utils';

export const renderColorPicker = (
  propertyType,
  selectedComponentInfo,
  globalTokens,
  editedTokens,
  handleTokenChange,
  tokenNames,
  setEditedTokens,
  setTokenNames,
  localValues,
  setLocalValues // <-- Accept setLocalValues as a parameter
) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const componentId = selectedComponentInfo.id;
  const tokenName = tokenNames[componentId]?.[propertyType] || '';
  const inputValue = localValues[propertyType] || tokenName || editedTokens[componentId]?.[propertyType] || '';

  console.log(`Render Color Picker for ${propertyType}`);
  console.log(`Component ID: ${componentId}`);
  console.log(`Token Name: "${tokenName}"`);
  console.log(`Input Value: "${inputValue}"`);
  console.log(`Local Values:`, localValues);

  const relevantTokens = getRelevantTokens(globalTokens, propertyType);
  console.log(`Relevant Tokens for ${propertyType}:`, relevantTokens);

  const menu = (
    <Menu
      onClick={(e) => {
        const tokenValue = relevantTokens[e.key];
        console.log(`Selected token from dropdown for ${propertyType}:`, tokenValue);
        handleTokenChange(propertyType, tokenValue);
        setEditedTokens((prevTokens) => ({
          ...prevTokens,
          [componentId]: {
            ...prevTokens[componentId],
            [propertyType]: tokenValue,
          },
        }));
        setTokenNames((prevTokenNames) => ({
          ...prevTokenNames,
          [componentId]: {
            ...prevTokenNames[componentId],
            [propertyType]: e.key,
          },
        }));
      }}
    >
      {Object.entries(relevantTokens).map(([key, value]) => (
        <Menu.Item key={key} onClick={() => console.log(`Menu.Item clicked: ${key} - ${value}`)}>
          {key} - {value}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleBlurOrEnter = (event) => {
    if (event.type === 'blur' || event.key === 'Enter') {
      const newValue = localValues[propertyType] || inputValue;
      console.log(`Blur or Enter detected for ${propertyType}, setting value: "${newValue}"`);
      handleTokenChange(propertyType, newValue);
    }
  };

  return (
    <div className="prop-sidebar-item prop-sidebar-item-color" style={{ position: 'relative', display: 'flex', alignItems: 'center' }} >
      <input
        className="color-picker"
        type="color"
        value={localValues[propertyType] || inputValue}
        onChange={(e) => handleTokenChange(propertyType, e.target.value)}
      />
      <Input
        type="text"
        value={localValues[propertyType] || tokenName || inputValue}
        placeholder={propertyType}
        onChange={(e) =>
          setLocalValues((prevValues) => ({
            ...prevValues,
            [propertyType]: e.target.value,
          }))
        }
        onBlur={handleBlurOrEnter}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleBlurOrEnter(e);
          }
        }}
      />
      {Object.keys(relevantTokens).length > 0 && (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button style={{ cursor: 'pointer' }}>
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};
