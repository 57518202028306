// frontend/src/components/homepage/hero/HeroMain.js
import React, { useEffect } from 'react';
import './HeroMain.css';
import AppScreen from '../../../assets/homepage/hero/hero-app-image.png';
import Shape1 from '../../../assets/homepage/hero/hero-shape-1.png';
import Shape2 from '../../../assets/homepage/hero/hero-shape-2.png';

const HeroMain = () => {
  
  useEffect(() => {
    const handleMouseMove = (event) => {
      const shapes = document.querySelectorAll('.shape');
      const { clientX, clientY } = event;

      shapes.forEach(shape => {
        const { left, top, width, height } = shape.getBoundingClientRect();
        const offsetX = ((clientX - (left + width / 2)) / width) * -10; // Move away from mouse
        const offsetY = ((clientY - (top + height / 2)) / height) * -10; // Move away from mouse

        // Set custom properties for smooth transitions
        shape.style.setProperty('--offset-x', `${offsetX}px`);
        shape.style.setProperty('--offset-y', `${offsetY}px`);
      });
    };

    const heroWrapper = document.querySelector('.hero-main-wrapper');
    heroWrapper.addEventListener('mousemove', handleMouseMove);

    return () => {
      heroWrapper.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="hero-main-wrapper">
      <div className="hero-main-contents">
        <h1>One App, Endless Possibilities</h1>
        <p>Design, develop, and refine as a team.</p>
        <div className="hero-images">
          <img src={Shape1} alt="Shape 1" className="shape shape1" />
          <img src={AppScreen} alt="App Screen" className="app-screen" />
          <img src={Shape2} alt="Shape 2" className="shape shape2" />
        </div>
      </div>
    </div>
  );
};

export default HeroMain;
