// frontend/src/hooks/useNestedComponentDrop.js
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { components as componentImports } from '../components/ComponentImports';
import { loadComponentFromS3, registerDynamicComponent } from '../components/DynamicComponentLoader';
import { getComponentProps } from './getComponentProps';
//import axios from 'axios';

const useNestedComponentDrop = (containerId, token, designTokens, editedTokens) => {
  const { addNestedComponent } = useContext(NestedComponentsContext);

  const handleNestedComponentDrop = async (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    if (!data) return;

    let dragData;
    try {
      dragData = JSON.parse(data);
    } catch (error) {
      console.error('Error parsing drag data:', error);
      return;
    }

    const { componentSource, componentName, designTokens: componentDesignTokens } = dragData;
    let ComponentModule;

    if (componentSource === 's3') {
      console.log(`Loading S3 component: ${componentName}`);
      ComponentModule = await loadComponentFromS3(componentName, token);
      if (ComponentModule) {
        registerDynamicComponent(componentImports, componentName, ComponentModule);
      } else {
        console.error(`Failed to load component "${componentName}" from S3.`);
        return;
      }
    } else if (componentSource === 'local') {
      // Load local component with dynamic import handling
      const ComponentImportFunction = componentImports[componentName];
      if (!ComponentImportFunction || typeof ComponentImportFunction !== 'function') {
        console.error(`Component import function not found or invalid for: ${componentName}`);
        return;
      }
      try {
        const module = await ComponentImportFunction();
        ComponentModule = module.default || module;
        if (!ComponentModule) {
          console.error(`Component module not resolved correctly for: ${componentName}`);
          return;
        }
      } catch (error) {
        console.error(`Error loading local component: ${componentName}`, error);
        return;
      }
    } else {
      console.error(`Unknown component source: ${componentSource}`);
      return;
    }

    if (!ComponentModule) {
      console.error(`Failed to resolve component module for: ${componentName}`);
      return;
    }

    // Simplified handling for Container component
    if (componentName === 'Container') {
      addNestedComponent(containerId, 0, {
        id: uuidv4(),
        component: ComponentModule,
        name: componentName,
        source: componentSource,
        s3ComponentName: componentSource === 's3' ? componentName : undefined,
        props: getComponentProps(ComponentModule),
        tokens: { ...designTokens[componentName], ...editedTokens[componentName], ...componentDesignTokens },
      });
      return;
    }

    const initialProps = getComponentProps(ComponentModule);
    const newComponentTokens = {
      ...designTokens[componentName],
      ...editedTokens[componentName],
      ...componentDesignTokens,
    };

    const newComponent = {
      id: uuidv4(),
      component: ComponentModule,
      name: componentName,
      source: componentSource,
      s3ComponentName: componentSource === 's3' ? componentName : undefined,
      props: initialProps,
      tokens: newComponentTokens,
    };

    addNestedComponent(containerId, 0, newComponent);
  };

  return handleNestedComponentDrop;
};

export default useNestedComponentDrop;
