// frontend/src/components/homepage/HomeGridLines/HomeGridLines.js
import React, { useEffect, useState } from 'react';
import './HomeGridLines.css';
import AnimatedKeyline from './AnimatedKeyline';

const HomeGridLines = () => {
  const [activeKeylines, setActiveKeylines] = useState([]);

  useEffect(() => {
    const selectRandomKeylines = () => {
      const selected = new Set();
      while (selected.size < 3) { // Ensure 3 keylines are selected
        selected.add(Math.floor(Math.random() * 11));
      }
      setActiveKeylines(Array.from(selected));
    };

    selectRandomKeylines(); // Initialize with 3 random keylines

    const interval = setInterval(selectRandomKeylines, 15000); // Change every 15 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return (
    <div className="home-grid-lines">
      {Array.from({ length: 11 }).map((_, index) => (
        <div className="column" key={index}>
          <div className="keyline" />
          {index === 10 && <div className="keyline right-edge" />}
          {activeKeylines.includes(index) && <AnimatedKeyline />}
        </div>
      ))}
    </div>
  );
};

export default HomeGridLines;
