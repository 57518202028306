// frontend/src/utils/reconstructFrontendStructureFromBackendSchema.js
import { components as componentImports } from '../components/ComponentImports';
import { loadComponentFromS3 } from '../components/DynamicComponentLoader';
import { v4 as uuidv4 } from 'uuid';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';

export const reconstructFrontendStructureFromBackendSchema = async (content, components, nestedComponentsContext, token) => {
  console.log('reconstructFrontendStructureFromBackendSchema - Reconstructing Content:', content);
  if (!Array.isArray(content)) {
    console.error("Invalid content structure:", content);
    return [];
  }

  let nestedComponentsMap = {};

  const reconstructedRows = await Promise.all(content.map(async rowData => {
    console.log('reconstructFrontendStructureFromBackendSchema - Row Data:', rowData);
    if (!rowData.columns || !Array.isArray(rowData.columns)) {
      console.error("Invalid rowData structure:", rowData);
      return null;
    }

    const columnData = await Promise.all(rowData.columns.map(async column => {
      const componentData = await Promise.all(column.components.map(async componentInfo => {
        let Component;

        if (componentInfo.source === 's3' && componentInfo.s3ComponentName) {
          Component = await loadComponentFromS3(componentInfo.s3ComponentName, token);
        } else if (componentInfo.source === 'local') {
          if (componentImports[componentInfo.name]) {
            if (typeof componentImports[componentInfo.name] === 'function') {
              // Dynamically import the component
              const module = await componentImports[componentInfo.name]();
              Component = module.default || module;
            } else if (componentImports[componentInfo.name]?.component) {
              // Component has been loaded and stored as an object with a 'component' property
              Component = componentImports[componentInfo.name].component;
            } else {
              console.error(`Component "${componentInfo.name}" is not a function or a loaded component.`);
              return null;
            }
          } else {
            console.error(`Local component "${componentInfo.name}" not found.`);
            return null;
          }
        } else if (!componentInfo.source) {
          console.warn(`Component "${componentInfo.name}" has no source defined. Defaulting to "local".`);
          if (componentImports[componentInfo.name]) {
            const module = await componentImports[componentInfo.name]();
            Component = module.default || module;
          } else {
            console.error(`Local component "${componentInfo.name}" not found.`);
            return null;
          }
        } else {
          console.error(`Unknown component source: "${componentInfo.source}"`);
          return null;
        }

        const componentId = componentInfo.id || uuidv4();

        // Reconstruct nested components and store them in nestedComponentsMap
        //if (componentInfo.nestedComponents && Object.keys(componentInfo.nestedComponents).length > 0) {
        if (componentInfo.nestedComponents && componentInfo.nestedComponents.length > 0) {
          const reconstructedNestedComponents = await reconstructNestedComponents(componentInfo.nestedComponents, token);

          // Store the reconstructed nested components in nestedComponentsMap
          nestedComponentsMap[componentId] = reconstructedNestedComponents;
        }

        return Component ? { 
          id: componentId,
          component: Component, 
          name: componentInfo.name, 
          source: componentInfo.source,
          s3ComponentName: componentInfo.s3ComponentName,
          props: componentInfo.props || {},
          tokens: componentInfo.tokens || {},
        } : null;
      }));

      return {
        columnSize: column.columnSize,
        components: componentData.filter(comp => comp !== null)
      };
    }));

    return {
      columns: rowData.columns.map(column => column.columnSize),
      components: columnData.map(column => column.components)
    };
  }));

  // Return both the reconstructed rows and the nestedComponentsMap
  return {
    rows: reconstructedRows.filter(row => row !== null),
    nestedComponents: nestedComponentsMap,
  };
};

async function reconstructNestedComponents(nestedComponentsData, token) {
  const nestedComponentsResult = {};

  if (Array.isArray(nestedComponentsData)) {
    for (const nestedComponentGroup of nestedComponentsData) {
      const columnId = nestedComponentGroup.columnId;
      const componentsArray = nestedComponentGroup.components;
      const reconstructedComponents = [];

      if (Array.isArray(componentsArray)) {
        for (const nestedComponentInfo of componentsArray) {
          let Component;

          if (nestedComponentInfo.source === 's3' && nestedComponentInfo.s3ComponentName) {
            Component = await loadComponentFromS3(nestedComponentInfo.s3ComponentName, token);
          } else if (nestedComponentInfo.source === 'local') {
            if (componentImports[nestedComponentInfo.name]) {
              if (typeof componentImports[nestedComponentInfo.name] === 'function') {
                const module = await componentImports[nestedComponentInfo.name]();
                Component = module.default || module;
              } else if (componentImports[nestedComponentInfo.name]?.component) {
                Component = componentImports[nestedComponentInfo.name].component;
              } else {
                console.error(`Component "${nestedComponentInfo.name}" is not a function or a loaded component.`);
                continue;
              }
            } else {
              console.error(`Local nested component "${nestedComponentInfo.name}" not found.`);
              continue;
            }
          } else if (!nestedComponentInfo.source) {
            console.warn(`Component "${nestedComponentInfo.name}" has no source defined. Defaulting to "local".`);
            if (componentImports[nestedComponentInfo.name]) {
              const module = await componentImports[nestedComponentInfo.name]();
              Component = module.default || module;
            } else {
              console.error(`Local component "${nestedComponentInfo.name}" not found.`);
              continue;
            }
          } else {
            console.error(`Unknown nested component source: "${nestedComponentInfo.source}"`);
            continue;
          }

          const nestedComponentId = nestedComponentInfo.id || uuidv4();

          const newComponent = {
            id: nestedComponentId,
            component: Component,
            name: nestedComponentInfo.name,
            source: nestedComponentInfo.source,
            s3ComponentName: nestedComponentInfo.s3ComponentName,
            props: nestedComponentInfo.props || {},
            tokens: nestedComponentInfo.tokens || {},
          };
          reconstructedComponents.push(newComponent);
          //nestedComponentsResult.push(newComponent);
        }
      }
      // Assign reconstructed components to the columnId in the result object
      nestedComponentsResult[columnId] = reconstructedComponents;
    }
  }

  return nestedComponentsResult;
}
