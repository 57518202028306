// frontend/src/components/homepage/HomeGridLines/AnimatedKeyline.js
import React, { useEffect, useRef } from 'react';
import './AnimatedKeyline.css';

const AnimatedKeyline = () => {
  const keylineRef = useRef(null);

  useEffect(() => {
    const keyline = keylineRef.current;

    const animate = () => {
      const duration = Math.random() * 3 + 2; // Random duration between 2 and 5 seconds
      const delay = Math.random() * 9 + 1; // Random delay between 1 and 10 seconds

      keyline.style.animation = `animateKeyline ${duration}s linear forwards`;
      
      // Use requestAnimationFrame for smoother animation
      setTimeout(() => {
        keyline.style.animation = ''; // Reset animation
        requestAnimationFrame(() => animate()); // Restart the animation after the delay
      }, (duration + delay) * 1000);
    };

    animate(); // Start the animation on mount
  }, []);

  return <div className="animated-keyline" ref={keylineRef} />;
};

export default AnimatedKeyline;
