// frontend/src/components/properties-sidebar/FlexOptions.js

import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, Menu, Input } from 'antd';
import {
  JustifyContentFlexStart,
  JustifyContentFlexEnd,
  AlignItemsFlexStart,
  AlignItemsFlexEnd,
  AlignItemsCenter,
  AlignItemsSpaceAround,
  AlignItemsSpaceBetween,
  AlignItemsSpaceEvenly,
  JustifyContentCenter,
  JustifyContentSpaceAround,
  JustifyContentSpaceBetween,
  JustifyContentSpaceEvenly,
  FlexWrapNowrap,
  FlexWrapWrap,
  FlexDirectionRowReverse,
  FlexDirectionRow,
  FlexDirectionColumn,
  FlexDirectionColumnReverse,
  MoreFlexOptions,
  ColumnGap,
  RowGap,
} from '../../assets/icons/properties-sidebar/icons';
import { getRelevantTokens } from './utils';
import { DownOutlined } from '@ant-design/icons';

const FlexOptions = ({
  globalTokens,
  editedTokens,
  designTokens,
  selectedComponentInfo,
  handleTokenChange,
  tokenNames,
  setEditedTokens,
  setTokenNames,
  viewportSize,
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const componentId = selectedComponentInfo.id;
  const currentTokens = 
    editedTokens[componentId]?.[viewportSize] ||
    designTokens[componentId]?.[viewportSize] ||
    (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
    {};

  // State to manage local inputs for gap properties
  const [localValues, setLocalValues] = useState({});

  // Initialize localValues based on current tokens
  useEffect(() => {
    const initialValues = {};
    const componentTokens =
      editedTokens[componentId]?.[viewportSize] ||
      designTokens[componentId]?.[viewportSize] ||
      (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
      {};

    console.log('FlexOptions useEffect: Loading initial tokens for component:', componentId);
    console.log('Edited Tokens:', editedTokens[componentId]?.[viewportSize]);
    console.log('Design Tokens:', designTokens[componentId]?.[viewportSize]);
    console.log(`Selected Component Tokens for viewport "${viewportSize}":`, selectedComponentInfo.tokens?.[viewportSize]);

    // Set initial values for each token property type
    Object.keys(componentTokens).forEach((propertyType) => {
      initialValues[propertyType] = componentTokens[propertyType];
      console.log(`Setting initial value for ${propertyType}:`, componentTokens[propertyType]);
    });

    setLocalValues(initialValues);
    console.log('FlexOptions: localValues set to:', initialValues);
  }, [componentId, viewportSize, editedTokens, designTokens, selectedComponentInfo.tokens]);

  const renderButton = (propertyType, value, icon) => {
    const isActive = currentTokens[propertyType] === value;
    return (
      <Button
        className={`flex-button ${isActive ? 'active' : ''}`}
        icon={icon}
        onClick={() => handleTokenChange(propertyType, value)}
      />
    );
  };

  const renderDropdown = (propertyType) => {
    const inputValue =
      localValues[propertyType] ||
      editedTokens[componentId]?.[viewportSize]?.[propertyType] ||
      designTokens[componentId]?.[viewportSize]?.[propertyType] ||
      selectedComponentInfo.tokens?.[propertyType] ||
      '';

    const tokenName = tokenNames[componentId]?.[propertyType] || '';
    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    console.log(`Rendering input for ${propertyType}: inputValue="${inputValue}", tokenName="${tokenName}"`);

    if (Object.keys(relevantTokens).length === 0) {
      console.warn(`No tokens found for ${propertyType}`);
    }

    const menu = (
      <Menu
        onClick={(e) => {
          const tokenValue = relevantTokens[e.key];
          console.log(`Selected token from dropdown for ${propertyType}:`, tokenValue);
          handleTokenChange(propertyType, tokenValue);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [componentId]: {
              ...prevTokens[componentId],
              [viewportSize]: {
                ...(prevTokens[componentId]?.[viewportSize] || {}),
                [propertyType]: tokenValue,
              },
            },
          }));
          // Update tokenNames to store the selected token name
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [componentId]: {
              ...prevTokenNames[componentId],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item
            key={key}
            onClick={() => console.log(`Menu.Item clicked: ${key} - ${value}`)}
          >
            {key} - {value}
          </Menu.Item>
        ))}
      </Menu>
    );

    console.log(`Rendering dropdown button for ${propertyType}`);

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <Button style={{ cursor: 'pointer' }}>
          <MoreFlexOptions />
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const renderGapInput = (label, icon, propertyType) => {
    const inputValue =
      localValues[propertyType] ||
      editedTokens[componentId]?.[viewportSize]?.[propertyType] ||
      designTokens[componentId]?.[viewportSize]?.[propertyType] ||
      selectedComponentInfo.tokens?.[propertyType] ||
      '';

    const tokenName = tokenNames[componentId]?.[propertyType] || '';
    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    console.log(`Rendering input for ${propertyType}: inputValue="${inputValue}", tokenName="${tokenName}"`);

    const menu = (
      <Menu
        onClick={(e) => {
          const tokenValue = relevantTokens[e.key];
          console.log(`Selected token from dropdown for ${propertyType}:`, tokenValue);
          handleTokenChange(propertyType, tokenValue);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [componentId]: {
              ...prevTokens[componentId],
              [viewportSize]: {
                ...(prevTokens[componentId]?.[viewportSize] || {}),
                [propertyType]: tokenValue,
              },
            },
          }));
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [componentId]: {
              ...prevTokenNames[componentId],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item key={key}>
            {key} - {value}
          </Menu.Item>
        ))}
      </Menu>
    );

    const handleBlurOrEnter = (event) => {
      if (event.type === 'blur' || event.key === 'Enter') {
        const newValue = localValues[propertyType] || inputValue;
        console.log(`Blur or Enter detected for ${propertyType}, setting value: "${newValue}"`);
        handleTokenChange(propertyType, newValue);
      }
    };

    return (
      <div
        className="prop-sidebar-item"
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        <label alt={label}>{icon}</label>
        <Input
          type="text"
          value={localValues[propertyType] || tokenName || inputValue}
          placeholder={propertyType}
          onChange={(e) => {
            const value = e.target.value;
            setLocalValues((prevValues) => ({
              ...prevValues,
              [propertyType]: value,
            }));
            handleTokenChange(propertyType, value);
          }}
          onBlur={handleBlurOrEnter}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleBlurOrEnter(e);
            }
          }}
          /*style={{ width: '150px' }}*/
        />
        {Object.keys(relevantTokens).length > 0 && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ cursor: 'pointer' }}>
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  };

  return (
    <>
      {/* Flex Alignment Options */}
      <Row gutter={[8, 8]}>
        <Col span={2}>{renderButton('justifyContent', 'flex-start', <JustifyContentFlexStart />)}</Col>
        <Col span={2}>{renderButton('justifyContent', 'flex-end', <JustifyContentFlexEnd />)}</Col>
        <Col span={2}>{renderButton('alignItems', 'flex-start', <AlignItemsFlexStart />)}</Col>
        <Col span={2}>{renderButton('alignItems', 'flex-end', <AlignItemsFlexEnd />)}</Col>
        <Col span={2}>{renderButton('justifyContent', 'center', <JustifyContentCenter />)}</Col>
        <Col span={2}>{renderButton('alignItems', 'center', <AlignItemsCenter />)}</Col>
      </Row>

      {/* Flex Wrapping and Direction Options */}
      <Row gutter={[8, 8]}>
        <Col span={2}>{renderButton('flexWrap', 'nowrap', <FlexWrapNowrap />)}</Col>
        <Col span={2}>{renderButton('flexWrap', 'wrap', <FlexWrapWrap />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'row-reverse', <FlexDirectionRowReverse />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'row', <FlexDirectionRow />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'column', <FlexDirectionColumn />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'column-reverse', <FlexDirectionColumnReverse />)}</Col>
      </Row>

      {/* Flex for Space Distribution */}
      <Row gutter={[8, 8]}>
        <Col span={2}>{renderButton('justifyContent', 'space-between', <JustifyContentSpaceBetween />)}</Col>
        <Col span={2}>{renderButton('justifyContent', 'space-around', <JustifyContentSpaceAround />)}</Col>
        <Col span={2}>{renderButton('justifyContent', 'space-evenly', <JustifyContentSpaceEvenly />)}</Col>
        <Col span={2}>{renderButton('alignContent', 'space-between', <AlignItemsSpaceBetween />)}</Col>
        <Col span={2}>{renderButton('alignContent', 'space-around', <AlignItemsSpaceAround />)}</Col>
        <Col span={2}>{renderButton('alignContent', 'space-evenly', <AlignItemsSpaceEvenly />)}</Col>
      </Row>

      {/* Gap Options */}
      <Row gutter={[8, 8]}>
        <Col span={12}>
          {renderGapInput('Column Gap', <RowGap />, 'columnGap')}
        </Col>
        <Col span={12}>
          {renderGapInput('Row Gap', <ColumnGap />, 'rowGap')}
        </Col>
      </Row>
    </>
  );
};

export default FlexOptions;
