// frontend/src/utils/rowColumnManager.js
export const addRow = (rows, setRows) => {
  setRows([
    ...rows,
    {
      columns: [1], // Default to 1 column
      components: [[]], // Default to an empty array of components
    },
  ]);
};

export const removeRow = (rows, setRows, index) => {
  const updatedRows = [...rows];
  updatedRows.splice(index, 1);
  setRows(updatedRows);
};

export const removeColumn = (rows, setRows, rowIndex, colIndex) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns.splice(colIndex, 1);
  updatedRows[rowIndex].components.splice(colIndex, 1);
  setRows(updatedRows);
};

export const addComponentToColumn = (rows, setRows, rowIndex, colIndex, newComponent) => {
  const updatedRows = [...rows];
  if (!Array.isArray(updatedRows[rowIndex].components)) {
    updatedRows[rowIndex].components = [];
  }
  if (!Array.isArray(updatedRows[rowIndex].components[colIndex])) {
    updatedRows[rowIndex].components[colIndex] = [];
  }
  updatedRows[rowIndex].components[colIndex].push(newComponent);
  setRows(updatedRows);
};

export const handleColumnChange = (rows, setRows, rowIndex, newColumnCount) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns = new Array(newColumnCount).fill(1);
  setRows(updatedRows);
};

export const handleColumnDivChange = (rows, setRows, rowIndex, colIndex, newColumnCount) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns[colIndex] = newColumnCount;
  setRows(updatedRows);
};