// frontend/src/components/properties-sidebar/ColorOptions.js

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { renderColorPicker } from './ColorOptionsHelper';

const ColorOptions = ({
  globalTokens,
  editedTokens,
  designTokens,
  selectedComponentInfo,
  handleTokenChange,
  tokenNames,
  setEditedTokens,
  setTokenNames,
  viewportSize,
}) => {
  const [localValues, setLocalValues] = useState({});

  useEffect(() => {
    if (selectedComponentInfo && selectedComponentInfo.id) {
      const componentId = selectedComponentInfo.id;
      const initialValues = {};

      // Extract tokens based on the current viewportSize
      const componentTokens =
        editedTokens[componentId]?.[viewportSize] ||
        designTokens[componentId]?.[viewportSize] ||
        (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
        {};

      console.log('ColorOptions useEffect: Loading initial tokens for component:', componentId);
      console.log('Edited Tokens:', editedTokens[componentId]?.[viewportSize]);
      console.log('Design Tokens:', designTokens[componentId]?.[viewportSize]);
      console.log(`Selected Component Tokens for viewport "${viewportSize}":`, selectedComponentInfo.tokens?.[viewportSize]);

      // Set initial values for each token property type
      Object.keys(componentTokens).forEach((propertyType) => {
        initialValues[propertyType] = componentTokens[propertyType];
        console.log(`Setting initial value for ${propertyType}:`, componentTokens[propertyType]);
      });

      setLocalValues(initialValues);
      console.log('ColorOptions: localValues set to:', initialValues);
    }
  }, [selectedComponentInfo, viewportSize, editedTokens, designTokens]);

  const handleLocalChange = (propertyType, newValue) => {
    console.log(`Updating local value for ${propertyType}:`, newValue);
    setLocalValues((prevValues) => ({
      ...prevValues,
      [propertyType]: newValue,
    }));
    handleTokenChange(propertyType, newValue);
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {renderColorPicker(
            'backgroundColor',
            selectedComponentInfo,
            globalTokens,
            editedTokens,
            handleLocalChange,
            tokenNames,
            setEditedTokens,
            setTokenNames,
            localValues,
            setLocalValues // <-- Pass setLocalValues here
          )}
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {renderColorPicker(
            'color',
            selectedComponentInfo,
            globalTokens,
            editedTokens,
            handleLocalChange,
            tokenNames,
            setEditedTokens,
            setTokenNames,
            localValues,
            setLocalValues // <-- And here
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ColorOptions;
