// frontend/src/components/ui_components/ContainerControlBar.jsx
import React, { useContext } from 'react';
import { BuilderContext } from '../../contexts/BuilderContext';
import { PlusOutlined, SettingOutlined, DragOutlined } from '@ant-design/icons';
import AddNestedComponent from './AddNestedComponent';

const ContainerControlBar = ({
  containerId,
  handleComponentClick,
  tokens,
  designTokens,
  editedTokens
}) => {
  const isBuilder = useContext(BuilderContext);

  if (!isBuilder) {
    return null; // Do not render anything in public view
  }
  
  return (
    <div className="ContainerControlBar">
      <div style={dragIconStyle}>
        <DragOutlined /> {/* Dragging functionality can be added here */}
      </div>
      <div
        style={barContentStyle}
        onClick={() => handleComponentClick({ id: containerId, name: 'Container' })}
      >
        Container
      </div>
      <div style={buttonGroupStyle}>
        <AddNestedComponent
          containerId={containerId}
          tokens={tokens}
          designTokens={designTokens}
          editedTokens={editedTokens}
        />
        <button style={iconButtonStyle}>
          <SettingOutlined />
        </button>
      </div>
    </div>
  );
};

const dragIconStyle = {
  cursor: 'move',
};

const barContentStyle = {
  flex: 1,
  textAlign: 'center',
  cursor: 'pointer',
};

const buttonGroupStyle = {
  display: 'flex',
  alignItems: 'center',
};

const iconButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  marginLeft: '5px',
};

export default ContainerControlBar;
