// frontend/App.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Route, Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import UserDashboard from './pages/UserDashboard';
import CodeEditor from './pages/CodeEditor';
import UiBuilder from './pages/UiBuilder';
import MediaPage from './pages/MediaPage';
import './App.css'; 
import { BuilderContext } from './contexts/BuilderContext';
import { TokensProvider } from './contexts/TokensContext';
import { ComponentPropsProvider } from './contexts/ComponentPropsContext';
import { NestedComponentsProvider} from './contexts/NestedComponentsContext';
import { PageContentProvider } from './contexts/PageContentContext';
import { SelectedComponentProvider } from './contexts/SelectedComponentContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
  
  return (
    <BuilderContext.Provider value={true}>
      <TokensProvider>
        <ComponentPropsProvider>
          <NestedComponentsProvider>
            <PageContentProvider>
              <SelectedComponentProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<LandingPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                    <Route
                      path="/dashboard"
                      element={
                        isLoggedIn ? <UserDashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/" />
                      }
                    />
                    <Route
                      path="/CodeEditor"
                      element={
                        isLoggedIn ? (
                          <CodeEditor isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                    <Route
                      path="/uibuilder"
                      element={
                        isLoggedIn ? <UiBuilder isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/" />
                      }
                    />
                    <Route
                      path="/media"
                      element={
                        isLoggedIn ? <MediaPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/" />
                      }
                    />
                  </Routes>
                </Router>
              </SelectedComponentProvider>
            </PageContentProvider>
          </NestedComponentsProvider>
        </ComponentPropsProvider>
      </TokensProvider>
    </BuilderContext.Provider>
  );
}

// Render the App component inside the 'root' div
ReactDOM.render(<App />, document.getElementById('root'));

export default App;