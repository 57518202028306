// frontend/src/hooks/usePageContentUtils.js
export const usePageContentUtils = (components, selectedComponentProps) => {
    const convertFrontendStructureToBackendSchema = (rows, nestedComponentsContext) => {
        // Logging the input data
        console.log('convertFrontendStructureToBackendSchema - Input:', JSON.stringify(rows, null, 2));
        console.log('Nested Components Context:', JSON.stringify(nestedComponentsContext, null, 2));
        console.log('convertFrontendStructureToBackendSchema - Input Rows:', rows);
        console.log('convertFrontendStructureToBackendSchema - Nested Components Context:', nestedComponentsContext);
        const convertedData = rows.map(row => ({
            columns: row.columns.map((columnSize, columnIndex) => {
                const componentsInColumn = row.components[columnIndex] || [];
                return {
                    columnSize,
                    components: componentsInColumn.map(component => {
                        const componentProps = selectedComponentProps[component.id] || component.props || {};
                        const nestedComponentsData = nestedComponentsContext[component.id] 
                            ? Object.entries(nestedComponentsContext[component.id]).map(([nestedColumnId, nestedComponents]) => ({
                                columnId: nestedColumnId,
                                components: nestedComponents.map(nestedComponent => ({
                                    id: nestedComponent.id,
                                    name: nestedComponent.name,
                                    source: nestedComponent.source,
                                    s3ComponentName: nestedComponent.s3ComponentName,
                                    props: nestedComponent.props || {},
                                    tokens: nestedComponent.tokens || {},
                                }))
                            }))
                            : [];

                        return {
                            id: component.id,
                            name: component.name,
                            source: component.source,
                            s3ComponentName: component.s3ComponentName,
                            props: componentProps,
                            tokens: component.tokens || {},
                            nestedComponents: nestedComponentsData
                        };
                    })
                };
            })
        }));
        return convertedData;
    };

    return { convertFrontendStructureToBackendSchema };
};