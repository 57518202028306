// frontend/src/utils/templates/NewComponentTemplate.js
const defaultComponentTemplate = `
import React, { useContext } from 'react';
import { TokensContext } from '../../../contexts/TokensContext';
import { ComponentPropsContext } from '../../../contexts/ComponentPropsContext';

const NewComponent = (props) => {
    const { editedTokens } = useContext(TokensContext);
    const { selectedComponentProps } = useContext(ComponentPropsContext);

    const componentProps = selectedComponentProps[props.id] || {};

    // Define the default tokens in the component
    const defaultTokens = {
        backgroundColor: '#ffffff', // Default background
        color: '#000000', // Default text color
        // Add more default tokens as needed
    };

    // Component specific styles or tokens
    const componentStyle = {
        ...defaultTokens, // Use default tokens
        ...componentProps, // Override with specific props if available
    };

    return (
        <div style={componentStyle}>
            {/* Render your component based on the props and context */}
            {props.children || 'New Component'}
        </div>
    );
};

NewComponent.defaultProps = {
    id: '',
    children: null,
};

export default NewComponent;
`;

export default defaultComponentTemplate;
