// frontend/src/components/media/MediaActions.js
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Upload, Modal, Input, message } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

const MediaActions = ({ selectedFolder, onActionCompleted }) => {
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [renameData, setRenameData] = useState({ oldKey: '', newKey: '' });

  // Handle file upload
  const handleUpload = async ({ file }) => {
    const fileType = selectedFolder.split('/')[1]; // Assumes selectedFolder is like 'userId/FileType/'
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('File uploaded successfully.');
      onActionCompleted();
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('Failed to upload file.');
    }
  };

  // Handle renaming
  const showRenameModal = (fileKey) => {
    setRenameData({ oldKey: fileKey, newKey: '' });
    setIsRenameModalVisible(true);
  };

  const handleRename = async () => {
    const { oldKey, newKey } = renameData;
    if (!newKey) {
      message.error('New name cannot be empty.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.put('/media/rename', {
        oldKey,
        newKey,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Renamed successfully.');
      setIsRenameModalVisible(false);
      setRenameData({ oldKey: '', newKey: '' });
      onActionCompleted();
    } catch (error) {
      console.error('Error renaming file:', error);
      message.error('Failed to rename file.');
    }
  };

  const handleRenameInputChange = (e) => {
    setRenameData(prev => ({ ...prev, newKey: e.target.value }));
  };

  // Handle delete
  const handleDelete = async (fileKey) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete('/media/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { fileKey },
      });
      message.success('File deleted successfully.');
      onActionCompleted();
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('Failed to delete file.');
    }
  };

  return (
    <div className="mediaActions">
      {/* Rename Modal */}
      <Modal
        title="Rename File/Folder"
        visible={isRenameModalVisible}
        onOk={handleRename}
        onCancel={() => setIsRenameModalVisible(false)}
        okText="Rename"
        cancelText="Cancel"
      >
        <Input
          placeholder="New name"
          value={renameData.newKey}
          onChange={handleRenameInputChange}
        />
      </Modal>
    </div>
  );
};

export default MediaActions;
