// frontend/src/hooks/useDesignTokenManagement.js
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';
import { ViewportContext } from '../contexts/ViewportContext';

export const useDesignTokenManagement = (token) => {
  const {
    designTokens,
    setDesignTokens,
    editedTokens,
    setEditedTokens,
    globalTokens,
    setGlobalTokens,
    updateDesignTokens,
  } = useContext(TokensContext);

  const viewportSize = useContext(ViewportContext);

  const fetchTokens = async () => {
    try {
      console.log('useDesignTokenManagement: Fetching tokens with viewport:', viewportSize);
      // Fetch Design Tokens
      const designTokenResponse = await axios.get('https://www.webbify.io/designTokens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Fetched designTokens:', designTokenResponse.data);
      
      // Map componentId to tokens
      const mappedDesignTokens = {};
      designTokenResponse.data.forEach(doc => {
        mappedDesignTokens[doc.componentId] = doc.tokens;
      });
      setDesignTokens(mappedDesignTokens);

      // Fetch Global Tokens
      const globalTokenResponse = await axios.get('https://www.webbify.io/globalTokens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Fetched globalTokens:', globalTokenResponse.data);
      setGlobalTokens(globalTokenResponse.data);

      // Initialize editedTokens only if they are empty, to prevent overwriting saved tokens.
      if (Object.keys(editedTokens).length === 0) {
        const initializedEditedTokens = {};
        Object.entries(mappedDesignTokens).forEach(([componentId, tokens]) => {
          if (tokens[viewportSize]) {
            initializedEditedTokens[componentId] = {
              [viewportSize]: { ...tokens[viewportSize] }
            };
          }
        });
        console.log('Initializing editedTokens:', initializedEditedTokens);
        setEditedTokens(initializedEditedTokens);
      }
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const handleTokenChange = (componentId, propertyType, newValue) => {
    if (!componentId || !viewportSize) {
      console.error('handleTokenChange: componentId or viewportSize is undefined');
      return;
    }

    setEditedTokens((prevTokens) => ({
      ...prevTokens,
      [componentId]: {
        ...prevTokens[componentId],
        [viewportSize]: {
          ...(prevTokens[componentId]?.[viewportSize] || {}),
          [propertyType]: newValue,
        },
      },
    }));

    // Update design tokens in the context based on viewport size
    updateDesignTokens(componentId, { [propertyType]: newValue }, viewportSize);
  };

  useEffect(() => {
    if (token) {
      console.log('useDesignTokenManagement: token available, fetching tokens');
      fetchTokens();
    } else {
      console.log('useDesignTokenManagement: no token available');
    }
  }, [token, viewportSize]);

  return {
    designTokens,
    editedTokens,
    globalTokens,
    fetchTokens,
    handleTokenChange,
    updateDesignTokens,
    setEditedTokens,
    setDesignTokens,
    setGlobalTokens,
  };
};
