// frontend/src/contexts/TokensContext.js
import React, { createContext, useState, useEffect } from 'react';

export const TokensContext = createContext();

export const TokensProvider = ({
  children,
  initialGlobalTokens = {},
  initialDesignTokens = {},
  initialEditedTokens = {},
}) => {
  const [globalTokens, setGlobalTokens] = useState(initialGlobalTokens);
  const [designTokens, setDesignTokens] = useState(initialDesignTokens);
  const [editedTokens, setEditedTokens] = useState(initialEditedTokens);
  const [forceRender, setForceRender] = useState(false);

  // Log initial tokens on load
  useEffect(() => {
    console.log('Initial tokens:', { globalTokens, designTokens, editedTokens });
  }, []);

  const updateDesignTokens = (componentId, newTokens, viewportSize) => {
    if (!componentId) {
      console.error('Cannot update tokens: componentId is undefined.');
      return;
    }

    console.log(`Updating design tokens for componentId ${componentId} and viewportSize ${viewportSize}`);
    setEditedTokens((prevTokens) => {
      const existingTokens = prevTokens[componentId]?.[viewportSize] || {};
      const updatedTokens = {
        ...prevTokens,
        [componentId]: {
          ...prevTokens[componentId],
          [viewportSize]: {
            ...existingTokens,
            ...newTokens,
          },
        },
      };
      console.log('TokensContext: updateDesignTokens called, new editedTokens:', updatedTokens);
      return updatedTokens;
    });
  };

  useEffect(() => {
    console.log('TokensContext - designTokens:', designTokens);
    console.log('TokensContext - editedTokens:', editedTokens);
    console.log('TokensContext - globalTokens:', globalTokens);
  }, [designTokens, editedTokens, globalTokens]);

  // Add useEffect to listen for token changes and trigger re-render
  useEffect(() => {
    if (Object.keys(editedTokens).length > 0) {
      setForceRender((prev) => !prev); // This should toggle and force a re-render
    }
  }, [editedTokens]);

  return (
    <TokensContext.Provider
      value={{
        globalTokens,
        designTokens,
        editedTokens,
        setGlobalTokens: (tokens) => {
          console.log('TokensContext: setGlobalTokens called with:', tokens);
          setGlobalTokens(tokens);
        },
        setDesignTokens: (tokens) => {
          console.log('TokensContext: setDesignTokens called with:', tokens);
          setDesignTokens(tokens);
        },
        setEditedTokens: (tokens) => {
          console.log('TokensContext: setEditedTokens called with:', tokens);
          setEditedTokens(tokens);
        },
        updateDesignTokens,
        forceRender,
      }}
    >
      {children}
    </TokensContext.Provider>
  );
};
