// frontend/src/components/media/MediaContainer.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, Button, Modal, Input, message, Popconfirm, Spin } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './MediaContainer.css';

const MediaContainer = ({ selectedFolder, mediaItems, refresh }) => {
  const [mediaItemsState, setMediaItemsState] = useState(mediaItems || []);
  const [loading, setLoading] = useState(false);
  const [renameModal, setRenameModal] = useState({ visible: false, oldKey: '', newKey: '' });

  useEffect(() => {
    if (selectedFolder && !mediaItems) {
      const fetchMediaItems = async () => {
        setLoading(true);
        try {
          const token = localStorage.getItem('token');
          const fileType = selectedFolder.split('/')[1];
          const response = await axios.get('/media/list', {
            headers: { Authorization: `Bearer ${token}` },
            params: { fileType },
          });
          setMediaItemsState(response.data.files);
        } catch (error) {
          console.error('Error fetching media items:', error);
          message.error('Failed to fetch media items.');
        } finally {
          setLoading(false);
        }
      };

      fetchMediaItems();
    } else {
      setMediaItemsState(mediaItems);
    }
  }, [selectedFolder, mediaItems, refresh]);

  // Filter out items that do not appear to be files (e.g., folders without a file extension)
  const filesOnly = mediaItemsState.filter(item => item.key.includes('.'));

  // Handle renaming
  const showRenameModal = (fileKey) => {
    setRenameModal({ visible: true, oldKey: fileKey, newKey: '' });
  };

  const handleRename = async () => {
    const { oldKey, newKey } = renameModal;
    if (!newKey) {
      message.error('New name cannot be empty.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.put('/media/rename', {
        oldKey,
        newKey,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Renamed successfully.');
      setRenameModal({ visible: false, oldKey: '', newKey: '' });
    } catch (error) {
      console.error('Error renaming file:', error);
      message.error('Failed to rename file.');
    }
  };

  const handleRenameInputChange = (e) => {
    setRenameModal(prev => ({ ...prev, newKey: e.target.value }));
  };

  // Handle delete
  const confirmDelete = async (fileKey) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete('/media/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { fileKey },
      });
      message.success('File deleted successfully.');
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('Failed to delete file.');
    }
  };

  // Determine media type based on file extension
  const getMediaType = (fileKey) => {
    const extension = fileKey.split('.').pop().toLowerCase();
    const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
    const videoTypes = ['mp4', 'mov', 'avi', 'wmv'];
    const documentTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const musicTypes = ['mp3', 'wav', 'aac'];

    if (imageTypes.includes(extension)) return 'image';
    if (videoTypes.includes(extension)) return 'video';
    if (documentTypes.includes(extension)) return 'document';
    if (musicTypes.includes(extension)) return 'music';
    return 'other';
  };

  return (
    <div className="mediaContainer">
      {loading ? (
        <div className="loading-spinner">
          <Spin size="large" tip="Loading files..." />
        </div>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={filesOnly}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type="link" icon={<EditOutlined />} onClick={() => showRenameModal(item.key)} />,
                <Popconfirm
                  title="Are you sure to delete this file?"
                  onConfirm={() => confirmDelete(item.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" icon={<DeleteOutlined />} danger />
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                avatar={
                  getMediaType(item.key) === 'image' ? (
                    <img src={`https://webbifymedia.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${item.key}`} alt={item.key} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                  ) : getMediaType(item.key) === 'video' ? (
                    <video width="50" height="50" controls>
                      <source src={`https://webbifymedia.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${item.key}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <span>{item.key.split('.').pop().toUpperCase()}</span>
                  )
                }
                title={item.key.split('/').pop()}
                description={`Size: ${(item.size / 1024).toFixed(2)} KB | Last Modified: ${new Date(item.lastModified).toLocaleString()}`}
              />
            </List.Item>
          )}
        />
      )}

      {/* Rename Modal */}
      <Modal
        title="Rename File/Folder"
        visible={renameModal.visible}
        onOk={handleRename}
        onCancel={() => setRenameModal({ visible: false, oldKey: '', newKey: '' })}
        okText="Rename"
        cancelText="Cancel"
      >
        <Input
          placeholder="New name"
          value={renameModal.newKey}
          onChange={handleRenameInputChange}
        />
      </Modal>
    </div>
  );
};

export default MediaContainer;
