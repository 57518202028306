// frontend/src/components/ComponentImports.js
export const components = {
  TextInput: () => import('./ui_components/TextInput/TextInput'),
  PrimaryButton: () => import('./ui_components/Button/PrimaryButton'),
  SecondaryButton: () => import('./ui_components/Button/SecondaryButton'),
  TertiaryButton: () => import('./ui_components/Button/TertiaryButton'),
  IconButton: () => import('./ui_components/Button/IconButton'),
  Text: () => import('./ui_components/Text/Text'),
  Image: () => import('./ui_components/Image/Image'),
  Container: () => import('./ui_components/Container/Container'),
  // Add other components as needed
  List: () => import('./ui_components/List/List'),
  Checkbox: () => import('./ui_components/Checkbox/Checkbox'),
  RadioButton: () => import('./ui_components/RadioButton/RadioButton'),
  TextArea: () => import('./ui_components/TextArea/TextArea'),
  Link: () => import('./ui_components/Link/Link'),
  Divider: () => import('./ui_components/Divider/Divider'),
  Card: () => import('./ui_components/Card/Card'),
  Carousel: () => import('./ui_components/Carousel/Carousel'),
  Hero: () => import('./ui_components/Hero/Hero'),
  Banner: () => import('./ui_components/Banner/Banner'),
  NotificationBanner: () => import('./ui_components/NotificationBanner/NotificationBanner'),
  HeroBanner: () => import('./ui_components/HeroBanner/HeroBanner'),
};
