// frontend/src/components/properties-sidebar/CustomExpandIcon.js
import React from 'react';
import { ReactComponent as ArrowHeadUp } from '../../assets/icons/properties-sidebar/arrow-head-up.svg';
import { ReactComponent as ArrowHeadDown } from '../../assets/icons/properties-sidebar/arrow-head-down.svg';

const CustomExpandIcon = ({ isActive }) => (
  <span>
    {isActive ? <ArrowHeadUp /> : <ArrowHeadDown />}
  </span>
);

export default CustomExpandIcon;
