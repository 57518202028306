// frontend/src/contexts/PageContentContext.js
import React, { createContext, useState, useCallback } from 'react';

export const PageContentContext = createContext();

export const PageContentProvider = ({ children, initialRows = [], initialPageContent = [] }) => {
  const [pageContent, setPageContent] = useState(initialPageContent);
  const [rows, setRows] = useState(initialRows);

  const handleSetPageContent = useCallback((newContent) => {
    setPageContent(newContent);
  }, []);

  const handleSetRows = useCallback(
    (newRows) => {
      setRows(newRows); // Set rows for the active page
    },
    [setRows]
  );

  return (
    <PageContentContext.Provider
      value={{
        pageContent,
        setPageContent: handleSetPageContent,
        rows,
        setRows: handleSetRows,
      }}
    >
      {children}
    </PageContentContext.Provider>
  );
};
