// frontend/src/components/PageContainer.js 
import React, { useContext, useState, useEffect } from 'react';
import { components as componentImports } from './ComponentImports';
import { loadComponentFromS3, registerDynamicComponent } from './DynamicComponentLoader'; // S3 components import
import { getComponentProps } from '../hooks/getComponentProps';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';
import useComponentDrop from '../hooks/useComponentDrop';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import { reconstructFrontendStructureFromBackendSchema } from '../utils/reconstructFrontendStructureFromBackendSchema';
import DivContainer from './ui_components/DivContainer/DivContainer'; 
import Container from './ui_components/Container/Container';
import ContainerControlBar from './ui_components/ContainerControlBar';
import {
  addRow,
  removeRow,
  removeColumn,
  addComponentToColumn,
  handleColumnChange,
  handleColumnDivChange,
} from '../utils/rowColumnManager';
import { TokensContext } from '../contexts/TokensContext';
import { ViewportContext } from '../contexts/ViewportContext';

const PageContainer = ({ 
  components, 
  designTokens, 
  globalTokens,
  //setSelectedComponentInfo,
  fetchDesignTokens, 
  updateDesignTokens,
  nestedComponents: initialNestedComponents,
}) => {
  const [renderedRows, setRenderedRows] = useState([]);
  const { rows, setRows, pageContent, setPageContent } = useContext(PageContentContext);
  const { setSelectedComponentInfo } = useContext(SelectedComponentContext);
  const { selectedComponentProps, updateComponentProps } = useContext(ComponentPropsContext);
  const { nestedComponents, setNestedComponents } = useContext(NestedComponentsContext);
  const { reconstructFrontendStructureFromBackendSchema } = usePageContentUtils(components);
  const handleComponentDrop = useComponentDrop(components, designTokens);
  const { forceRender, editedTokens } = useContext(TokensContext);
  const viewportSize = useContext(ViewportContext);

  const getViewportWidth = () => {
    switch (viewportSize) {
      case 'small':
        return '375px'; // Mobile
      case 'medium':
        return '768px'; // Tablet
      case 'large':
        return '1024px'; // Laptop
      default:
        return viewportSize; // Custom value
    }
  };

  const containerStyle = {
    minWidth: getViewportWidth(),
    //width: getViewportWidth(),
    margin: '0 auto',
  };

  useEffect(() => {
    if (initialNestedComponents) {
      setNestedComponents(initialNestedComponents);
      console.log('PageContainer: nestedComponents set in context:', initialNestedComponents);
    }
  }, [initialNestedComponents, setNestedComponents]);

  // Utils implementing rowColumnManager functions
  const onAddRow = () => addRow(rows, setRows);
  const onRemoveRow = (index) => removeRow(rows, setRows, index);
  const onRemoveColumn = (rowIndex, colIndex) => removeColumn(rows, setRows, rowIndex, colIndex);
  const onAddComponentToColumn = (rowIndex, colIndex, newComponent) => addComponentToColumn(rows, setRows, rowIndex, colIndex, newComponent);
  const onHandleColumnChange = (rowIndex, newColumnCount) => handleColumnChange(rows, setRows, rowIndex, newColumnCount);
  const onHandleColumnDivChange = (rowIndex, colIndex, newColumnCount) => handleColumnDivChange(rows, setRows, rowIndex, colIndex, newColumnCount);

  const handleComponentDragStart = (event, rowIndex, colIndex) => {
    event.dataTransfer.setData('sourceRow', rowIndex.toString());
    event.dataTransfer.setData('sourceCol', colIndex.toString());
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  // utils for handleComponentDrop function
  const onComponentDrop = (event, rowIndex, colIndex, containerId = null) => {
    // Check if the event is a valid drag event
    if (event && typeof event.preventDefault === 'function') {
      handleComponentDrop(componentImports, rows, setRows, event, rowIndex, colIndex, containerId);
      savePageContentToContext();
    } else {
      console.error('Invalid drop event:', event);
    }
  };

  const handleComponentClick = (component) => {
      console.log('handleComponentClick called with component:', component);
      if (!component.id) {
        console.error('handleComponentClick: Component ID is undefined:', component);
        return;
      }

      const tokens = {
        ...component.tokens?.[viewportSize],
        ...designTokens[component.id]?.[viewportSize],
        ...editedTokens[component.id]?.[viewportSize],
      };
      const componentProps = selectedComponentProps[component.id] || getComponentProps(component.component);

      console.log('PageContainer - Tokens on component click:', tokens);
      console.log('PageContainer - Props on component click:', componentProps);

      setSelectedComponentInfo({ 
        id: component.id,
        name: component.name,
        props: componentProps,
        tokens: tokens,
      });

      // Ensure the selected component props are updated in the context as well
      updateComponentProps(component.id, componentProps);
      console.log('Updated component props after click:', componentProps);
  };

  // Render components and handle on click behaviour
  const renderedComponents = (componentsArray, rowIndex, colIndex) => {
    if (!Array.isArray(componentsArray)) {
      console.error("Invalid componentsArray:", componentsArray);
      return null;
    }

    return componentsArray.map((componentInfo, index) => {
      //console.log(`Rendering component ${componentInfo.name} with ID ${componentInfo.id}`);
      if (!componentInfo || !componentInfo.component) {
        console.warn('Invalid or unloaded component:', componentInfo);
        return null;
      }

      if (!componentInfo.id) {
        console.error('Component is missing an ID:', componentInfo);
        return null; // Prevent rendering components without ID
      }

      const Component = componentInfo.component;
      const componentId = componentInfo.id;

      const tokens = {
        ...componentInfo.tokens?.[viewportSize],
        ...designTokens[componentInfo.id]?.[viewportSize],
        ...editedTokens[componentInfo.id]?.[viewportSize],
      };

      // Use getComponentProps utility to get the component's props
      //const componentProps = selectedComponentProps[componentInfo.id] || getComponentProps(componentInfo.component);
      const componentProps = selectedComponentProps[componentInfo.id] || componentInfo.props || getComponentProps(componentInfo.component);

      // Update selectedComponentProps if not already set
      if (!selectedComponentProps[componentInfo.id]) {
          updateComponentProps(componentInfo.id, componentProps);
      }

      return (
        <div 
          key={componentId}
          id={componentId}
          draggable="true"
          className="rendered-component" 
          onDragStart={(e) => handleComponentDragStart(e, rowIndex, colIndex)}
          data-component-name={componentInfo.name}
          data-component-source={componentInfo.source} 
          data-s3-component-name={componentInfo.s3ComponentName || ''} 
          onClick={() => handleComponentClick(componentInfo)}
        >
          <Component 
            key={componentId}
            {...componentProps}
            id={componentId}
            tokens={tokens}
            onClick={() => handleComponentClick(componentInfo)}
          />
        </div>
      );
    });
  };

  // This function handles rendering for Container and DivContainer components with nested components
  const renderedContainerComponents = (componentInfo, rowIndex, colIndex) => {
    const tokens = {
      ...componentInfo.tokens?.[viewportSize],
      ...designTokens[componentInfo.id]?.[viewportSize],
      ...editedTokens[componentInfo.id]?.[viewportSize],
    };
    
    const componentProps = selectedComponentProps[componentInfo.id] || componentInfo.props || getComponentProps(componentInfo.component);
    
    // Update selectedComponentProps if not already set
    if (!selectedComponentProps[componentInfo.id]) {
        updateComponentProps(componentInfo.id, componentProps);
    }

    const nestedComponentsForContainer = nestedComponents[componentInfo.id] || {};

    if (componentInfo.name === 'Container' || componentInfo.name === 'HeroBanner') {
      return (
        <div
          key={componentInfo.id}
          id={componentInfo.id}
          style={{ width: '100%' }}
          onDrop={(event) => handleComponentDrop(event, rowIndex, colIndex, componentInfo.id)}
          onDragOver={(event) => event.preventDefault()}
        >
          <ContainerControlBar
            handleComponentClick={() => handleComponentClick(componentInfo)}
            containerId={componentInfo.id}
          />
          <Container
            id={componentInfo.id}
            {...componentProps}
            tokens={tokens}
            handleComponentClick={handleComponentClick}
          >
            {Object.keys(nestedComponentsForContainer).map((columnId) => {
              const componentsArray = nestedComponentsForContainer[columnId];
              return Array.isArray(componentsArray)
                ? componentsArray.map((nestedComponent, nestedIndex) => {
                    const NestedComponent = nestedComponent.component;

                    const nestedTokens = {
                      ...nestedComponent.tokens?.[viewportSize],
                      ...designTokens[nestedComponent.id]?.[viewportSize],
                      ...editedTokens[nestedComponent.id]?.[viewportSize],
                    };
                    
                    const nestedProps = selectedComponentProps[nestedComponent.id] || nestedComponent.props || getComponentProps(nestedComponent.component);

                    // Update selectedComponentProps if not already set
                    if (!selectedComponentProps[nestedComponent.id]) {
                      updateComponentProps(nestedComponent.id, nestedProps);
                    }

                    if (!nestedComponent.id) {
                      console.error('Nested component is missing an ID:', nestedComponent);
                      return null;
                    }

                    console.log(`Rendering nested component: ${nestedComponent.name} with ID: ${nestedComponent.id}`);
                    console.log(`Nested Component Props:`, nestedProps);
                    console.log(`Nested Component Tokens:`, nestedTokens);

                    return (
                      <div
                        key={nestedComponent.id}
                        id={nestedComponent.id}
                        className="nested-component"
                        data-component-name={nestedComponent.name}
                        data-component-source={nestedComponent.source}
                        data-s3-component-name={nestedComponent.s3ComponentName || ''}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleComponentClick(nestedComponent);
                        }}
                      >
                        {nestedComponent.name === 'Container' || nestedComponent.name === 'DivContainer' ? (
                          <Container
                            id={nestedComponent.id}
                            {...nestedProps}
                            tokens={nestedTokens}
                            handleComponentClick={handleComponentClick}
                          />
                        ) : (
                          <NestedComponent 
                            id={nestedComponent.id}
                            {...nestedProps} 
                            tokens={nestedTokens}
                          />
                        )}
                      </div>
                    );
                  })
                : null;
            })}
          </Container>
        </div>
      );
    }

    return null;
  };

  // useEffect hook for updating renderedRows when rows are updated
  useEffect(() => {
    const newRenderedRows = rows.map((row, rowIndex) => ({
      ...row,
      components: row.components.map((columnComponents, colIndex) => {
        return Array.isArray(columnComponents)
          ? columnComponents.map((componentInfo) =>
              componentInfo.name === 'DivContainer' || componentInfo.name === 'Container'
                ? renderedContainerComponents(componentInfo, rowIndex, colIndex)
                : renderedComponents([componentInfo], rowIndex, colIndex)
            )
          : null;
      }),
    }));

    setRenderedRows(newRenderedRows);
  }, [rows, designTokens, editedTokens, nestedComponents, forceRender]);

  const savePageContentToContext = () => {
    const rowsArray = document.querySelectorAll('.page-container .row');
    const pageContentStructure = Array.from(rowsArray).map(row => {
      const columnsArray = row.querySelectorAll('.column');
      return {
        columns: Array.from(columnsArray).map(column => {
          const componentsArray = column.querySelectorAll('.rendered-component');
          return {
            columnSize: parseInt(column.getAttribute('data-column-size'), 10),
            components: Array.from(componentsArray).map(component => {
              const componentName = component.getAttribute('data-component-name');
              const componentSource = component.getAttribute('data-component-source'); // Retrieved from DOM
              const s3ComponentName = component.getAttribute('data-s3-component-name'); // Retrieved from DOM
              const componentId = component.getAttribute('id');
              const componentProps = selectedComponentProps[componentId] || {};  
              return {
                id: componentId,
                name: componentName,
                source: componentSource, // Now correctly retrieved
                s3ComponentName: s3ComponentName, // Now correctly retrieved
                props: componentProps,
                tokens: editedTokens[componentId] || {},
                nestedComponents: nestedComponents[componentId] || [],
                // Include other necessary properties like 'tokens' if required
              };
            }),
          };
        }),
      };
    });
    //console.log('PageContainer - Saving page content:', pageContentStructure);
    setPageContent(pageContentStructure); // Update the React context
  };

  const getColumnSize = (column) => {
      const columnSize = column.getAttribute('data-column-size');
      return columnSize ? parseInt(columnSize, 10) : null;
  };

  return (
    <div className="page-container" style={containerStyle}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="container">
          <div className="bar-container">
            <div className="column-options">
              <button onClick={() => onHandleColumnChange(rowIndex, row.columns.length + 1)}>
                +
              </button>
              {row.columns.map((colCount, colIndex) => (
                <div key={colIndex}>
                  <select
                    value={colCount}
                    onChange={(e) =>
                      onHandleColumnDivChange(rowIndex, colIndex, parseInt(e.target.value))
                    }
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((count) => (
                      <option key={count} value={count}>
                        {count} Col{count > 1 ? 's' : ''}
                      </option>
                    ))}
                  </select>

                  <button onClick={() => onRemoveColumn(rowIndex, colIndex)}>–</button>
                </div>
              ))}
            </div>
            <button onClick={() => onRemoveRow(rowIndex)}>Remove Row</button>
          </div>
          <div className="row">
            {row.columns.map((colCount, colIndex) => (
              <div
                key={colIndex}
                className="column"
                style={{ flex: `1 ${(100 / row.columns.reduce((acc, curr) => acc + curr, 0)) * colCount}%` }}
                data-column-size={colCount}
                onDrop={(event) => handleComponentDrop(event, rowIndex, colIndex)}
                onDragOver={allowDrop}
              >
                {row.components[colIndex] && row.components[colIndex].map((component, componentIndex) => (
                  <React.Fragment key={component.id}>
                    {component.name === 'DivContainer' || component.name === 'Container' ? (
                      renderedContainerComponents(component, rowIndex, colIndex)
                    ) : (
                      renderedComponents([component], rowIndex, colIndex)
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="add-outter-row">
        <button className="add-row" onClick={onAddRow}>Add Row</button>
      </div>
    </div>
  );
};

export default PageContainer;
