// frontend/src/components/UserURLSettings.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input, Button } from 'antd';

const UserURLSettings = () => {
  const [userurl, setUserurl] = useState('');
  const [currentURL, setCurrentURL] = useState('');
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState('');
  
  useEffect(() => {
    const fetchUserURL = async () => {
      const token = localStorage.getItem('token');
      try {
        const userResponse = await axios.get('/auth/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserId(userResponse.data._id);

        const response = await axios.get('/userurl/get', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.userURL) {
          setCurrentURL(response.data.userURL.userurl);
        }
      } catch (error) {
        console.error('Error fetching user URL:', error);
      }
    };
    fetchUserURL();
  }, []);

  const handleSetURL = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        '/userurl/set',
        { userurl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrentURL(response.data.userURL.userurl);
      setMessage('URL set successfully!');
    } catch (error) {
      console.error('Error setting user URL:', error);
      setMessage(error.response?.data?.message || 'Error setting URL');
    }
  };

  return (
    <div>
      <h2>Website Settings</h2>
      <p>Set your custom URL:</p>
      <Input
        placeholder="Enter custom URL"
        value={userurl}
        onChange={(e) => setUserurl(e.target.value)}
      />
      <Button onClick={handleSetURL} style={{ marginTop: '10px' }}>
        Set URL
      </Button>
      {currentURL && userId && (
        <p>
          Your current URL is:{' '}
          <a href={`/${userId}/${currentURL}`}>{`www.webbify.io/${userId}/${currentURL}`}</a>
        </p>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default UserURLSettings;
