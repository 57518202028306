// frontend/src/components/pagesSidebar.js
import React, { useState } from 'react';
import { DeleteOutlined, CheckOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';

const PagesSidebar = ({ 
  pages, 
  activePage,
  openTabs,
  setOpenTabs,
  onSelectPage, 
  onRemovePage, 
  onRenamePage, 
  onAddPage, 
  setActivePage,
  savePageContent
}) => {
  const [newPageName, setNewPageName] = useState('');
  const [editingPageIndex, setEditingPageIndex] = useState(-1);

  const handleSelectPage = (pageId) => {
    if (!openTabs.includes(pageId)) {
      setOpenTabs([...openTabs, pageId]); // Open a new tab if it isn't already open
    }
    setActivePage(pageId);  // Use setActivePage here
    onSelectPage(pageId);
  };

  const handleRenameClick = (pageIndex) => {
    setEditingPageIndex(pageIndex);
  };

  const handleConfirmClick = (pageIndex) => {
    if (pageIndex === editingPageIndex) {
      if (!pages[pageIndex].pageName.trim()) {
        alert('Page name cannot be empty.');
      } else {
        onRenamePage(pageIndex, pages[pageIndex].pageName);
        setActivePage(pages[pageIndex].pageId);
      }
      setEditingPageIndex(-1);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const pageIndex = editingPageIndex;
    if (pageIndex !== -1) {
      const updatedPages = [...pages];
      updatedPages[pageIndex].pageName = value;
      onRenamePage(pageIndex, value);
    }
  };

  const handleTokenChange = (key, newValue) => {
      setEditedTokens(prevTokens => ({
          ...prevTokens,
          [key]: newValue,
      }));
      onTokenChange(editedTokens);  // Propagate the change up to the ancestor component
  };

  return (
    <div className="pages-sidebar">
      <div className="pages-sidebar-controls">
        <input
          type="text"
          placeholder="Page name"
          value={newPageName}
          onChange={(e) => setNewPageName(e.target.value)}
        />
        <button onClick={() => onAddPage(newPageName)}>Create</button>
      </div>
      <h4>Pages</h4>
      <ul>
        {pages.map((page, index) => (
          <li key={page.pageId} className={page.pageId === activePage ? "active" : ""}>
            {editingPageIndex === index ? (
              <li>
                <input
                  type="text"
                  value={page.pageName}
                  onChange={handleInputChange}
                />
                <button onClick={() => handleConfirmClick(index)}><CheckOutlined /></button>
              </li>
            ) : (
              <>
                <span onClick={() => onSelectPage(page.pageId)} className="page-name">
                  {page.pageName}
                </span>
                {pages.length > 1 && (
                  <button onClick={() => onRemovePage(page.pageId)}><DeleteOutlined /></button>
                )}
                <button onClick={() => handleRenameClick(index)}>
                  {editingPageIndex === index ? <CloseOutlined /> : <EditOutlined />}
                </button>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PagesSidebar;