// frontend/src/components/gitHub/GitHubRepoViewer.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tree, Layout } from 'antd';
import GitHubFileView from './GitHubFileView';

const { Sider, Content } = Layout;

const GitHubRepoViewer = ({ selectedRepo }) => {
  const [treeData, setTreeData] = useState([]);
  const [fileContents, setFileContents] = useState('');
  const [selectedFileKey, setSelectedFileKey] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (selectedRepo) {
      fetchRepoTreeFromS3(selectedRepo);
    }
  }, [selectedRepo]);

  const fetchRepoTreeFromS3 = async (repoName) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/github/${repoName}/files`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = Array.isArray(response.data) ? response.data : [];
      console.log('Tree Data:', data); // Log to verify tree data
      setTreeData(data);
    } catch (error) {
      console.error('Error fetching repo tree from S3:', error);
    }
  };

  const handleFileSelect = (selectedKeys) => {
    const fileKey = selectedKeys[0];
    setSelectedFileKey(fileKey);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={233}>
        <Tree
          treeData={treeData}
          onSelect={handleFileSelect}
          //onSelect={(selectedKeys) => fetchFileContent(selectedKeys[0])}
        />
      </Sider>
      <Content style={{ padding: '0' }}>
        <h2>File Content</h2>
        <GitHubFileView
          visible={isModalVisible}
          onClose={handleCloseModal}
          fileKey={selectedFileKey}
          selectedRepo={selectedRepo}
        />
        <pre>{fileContents}</pre>
      </Content>
    </Layout>
  );
};

export default GitHubRepoViewer;
