import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      console.log('Sending login request...');
      const response = await axios.post('https://www.webbify.io/auth/login', {
        email,
        password,
      });

      const { token, userId } = response.data;
      // Store token in local storage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);

      // Set the user's login status to true
      setIsLoggedIn(true);
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the range of 2xx
        setError(error.response.data.message || 'An error occurred.');
      } else if (error.request) {
        // The request was made, but no response was received
        setError('No response received from the server.');
      } else {
        // Something happened in setting up the request that triggered an error
        setError('An error occurred. Please check your email and password.');
      }
    }

    setIsLoading(false);
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default Login;