// pageUtils.js
import { v4 as uuidv4 } from 'uuid';

export const generateUniquePageId = () => {
  return 'page_' + new Date().getTime() + '_' + Math.random().toString(36).substr(2, 9);
};


export const verifyUniquePageIds = (pages) => {
    const ids = pages.map(page => page.pageId);
    const uniqueIds = new Set(ids);
    console.assert(ids.length === uniqueIds.size, 'Duplicate pageId found');
};
