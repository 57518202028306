// frontend/src/components/LandingPage.js
import React from 'react';
import Header from '../components/Header';
import Login from '../components/Login';
//import Register from '../components/Register';
import LandingHero from '../components/LandingHero';
import HomeGridLines from '../components/homepage/HomeGridLines/HomeGridLines';
import HeroMain from '../components/homepage/hero/HeroMain';
import HomeSection1 from '../components/homepage/HomeSection1/HomeSection1';
import HomeSection2 from '../components/homepage/HomeSection2/HomeSection2';
import HomeSection3 from '../components/homepage/HomeSection3/HomeSection3';
import HomeSection4 from '../components/homepage/HomeSection4/HomeSection4';
import HomeTitleReveal from '../components/homepage/HomeTitleReveal/HomeTitleReveal';
import HomeSection5 from '../components/homepage/HomeSection5/HomeSection5';
import HomeSection6 from '../components/homepage/HomeSection6/HomeSection6';
import LandingContentSection from '../components/LandingContentSection';
import LandingKeyFeatures from '../components/LandingKeyFeatures';
import MainFooter from '../components/MainFooter';
import './LandingPage.css';

const LandingPage = ({ isLoggedIn, setIsLoggedIn }) => {
  return (
    <div className="page-contents">
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="HeroMainParent">
        <HeroMain />
      </div>
      <div className="HeroTopSections">
        <HomeGridLines />
        <HomeSection1 />
        <HomeSection2 />
        <HomeSection3 />
        <HomeSection4 />
        <HomeTitleReveal />
        <HomeSection5 />
        <HomeSection6 />
      </div>
      <MainFooter />
    </div>
  );
};

export default LandingPage;