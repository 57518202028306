// frontend/src/utils/pageCreationUtils.js
import React from 'react';
import PageContainer from '../components/PageContainer';
import { generateUniquePageId } from './pageUtils';

export const createNewPage = (
  newPageName,
  components,
  designTokens,
  rows,
  pages,
  //selectedComponentInfo,
  //setSelectedComponentInfo,
  editedTokens,
  setEditedTokens,
  setPages,
  setActivePage,
  savePageContent
) => {
  console.log('createNewPage called');

  if (!newPageName) {
    alert('Please enter a page name.');
    return;
  }

  const newPageId = generateUniquePageId();
  console.log('setActivePage is a function:', typeof setActivePage === 'function');
  const newPage = {
    pageId: newPageId,
    pageName: newPageName || 'New Page',
    content: (
      <PageContainer
        components={components}
        designTokens={designTokens}
        rows={[]}
        //selectedComponentInfo={selectedComponentInfo}
        //setSelectedComponentInfo={setSelectedComponentInfo}
        editedTokens={editedTokens}
        setEditedTokens={setEditedTokens}
      />
    ),
  };

  setPages(prevPages => [...prevPages, newPage]);
  setActivePage(newPageId);
  //savePageContent(newPageId);
  return newPage;
};
