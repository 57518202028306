// frontend/src/components/code-editor/CodeEditorFilesSidebar.js
import React, { useState, useEffect } from 'react';
import { DeleteOutlined, CheckOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';

const CodeEditorFilesSidebar = ({ 
  pages,
  onSelectPage, 
  activePage
}) => {
  const [newFileName, setNewFileName] = useState('');

  const handleSelectPageClick = (pageName) => {
    onSelectPage(pageName);
  };

  return (
    <div className="files-sidebar">
      <div className="files-sidebar-controls">
        <input
          type="text"
          placeholder="File name"
          value={newFileName}
          onChange={(e) => setNewFileName(e.target.value)}
        />
        <button onClick={() => console.log('Create file with name:', newFileName)}>Create</button>
      </div>
      <h4>Pages</h4>
      <ul>
        {pages.map((page) => (
          <li
            key={page}
            className={activePage === page ? 'active' : ''}
            onClick={() => handleSelectPageClick(page)}
          >
            {page}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CodeEditorFilesSidebar;
