// frontend/src/contexts/ViewportContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create the context with a default value
export const ViewportContext = createContext('large');

// ViewportProvider Component
export const ViewportProvider = ({ children }) => {
  const [viewport, setViewport] = useState(getViewportSize());

  useEffect(() => {
    console.log('ViewportProvider - viewport:', viewport);
  }, [viewport]);

  useEffect(() => {
    const handleResize = () => setViewport(getViewportSize());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  );
};

// Utility function to determine viewport size
function getViewportSize() {
  const width = window.innerWidth;
  if (width < 768) return 'small';
  if (width < 1024) return 'medium';
  return 'large';
}
