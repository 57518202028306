// frontend/src/hooks/useSavePageContent.js
import { useContext, useCallback } from 'react';
import axios from 'axios';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { usePageContentUtils } from './usePageContentUtils';
import { generateUniquePageId } from '../utils/pageUtils';
import PageContainer from '../components/PageContainer';

const generateCSSContent = (editedTokens) => {
  console.log('generateCSSContent - editedTokens:', editedTokens);
  let cssContent = '';

  const mediaQueries = {
    small: '@media (max-width: 600px)',
    medium: '@media (min-width: 601px) and (max-width: 1024px)',
    large: '@media (min-width: 1025px)',
  };

  for (const [componentId, viewports] of Object.entries(editedTokens)) {
    if (!componentId || componentId === 'undefined') {
      console.warn('Skipping undefined componentId in editedTokens');
      continue;
    }

    console.log(`Processing component ID: ${componentId}`);
    
    for (const [viewportSize, tokens] of Object.entries(viewports)) {
      console.log(`  Viewport: ${viewportSize}, Tokens:`, tokens);
      let cssRules = '';
      for (const [property, value] of Object.entries(tokens)) {
        console.log(`    Property: ${property}, Value: ${value}`);

        // Validate property and value
        if (!property || typeof property !== 'string') {
          console.warn(`Invalid property name: "${property}" for component "${componentId}"`);
          continue;
        }
        if (typeof value !== 'string' && typeof value !== 'number') {
          console.warn(`Invalid value type for property "${property}" in component "${componentId}":`, value);
          continue;
        }
        cssRules += `  ${property}: ${value};\n`;
      }

      if (cssRules) {
        const mediaQuery = mediaQueries[viewportSize] || `@media (${viewportSize})`;
        cssContent += `${mediaQuery} {\n`;
        cssContent += `  #${componentId} {\n`;
        cssContent += `${cssRules}`;
        cssContent += `  }\n`;
        cssContent += `}\n`;
      }
    }
  }

  if (!cssContent) {
    console.warn('Generated CSS content is empty.');
    // Optionally, skip saving CSS if content is empty
    return '';
  }

  console.log('generateCSSContent - cssContent:', cssContent);
  return cssContent;
};

const useSavePageContent = (
  token, 
  pages, 
  setPages,
  activePage,
  components, 
  designTokens, 
  selectedComponentInfo, 
  setSelectedComponentInfo, 
  editedTokens, 
  setEditedTokens,
  selectedComponentProps
) => {
    const { rows } = useContext(PageContentContext);
    const { nestedComponents } = useContext(NestedComponentsContext);
    const { convertFrontendStructureToBackendSchema } = usePageContentUtils(components, selectedComponentProps);

    const savePageContent = useCallback(async (pageIdToSave, pageToSave) => {
      console.log('Attempting to save page:', pageIdToSave);
      if (!token) {
        console.error('No token available.');
        return;
      }

      if (!pageToSave) {
        console.error('Page to save is not defined.');
        return;
      }

      // Convert the front-end structure to the back-end schema format.
      const pageContentForBackend = convertFrontendStructureToBackendSchema(rows, nestedComponents);
      console.log('Converted page content for backend:', pageContentForBackend);

      try {
        // Save the page content
        const saveResponse = await axios.put(
          'https://www.webbify.io/pageContent',
          {
            pageId: pageIdToSave,
            pageName: pageToSave.pageName,
            content: { rows: pageContentForBackend },
            //designTokens,
            //editedTokens,
            //nestedComponents,
            //selectedComponentInfo
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Save Response:', saveResponse.data);

        // After saving page content, generate and save CSS content
        const cssContent = generateCSSContent(editedTokens);
        console.log('Generated CSS Content:', cssContent);

        // Ensure cssContent is not empty
        if (!cssContent) {
          console.warn('Generated CSS content is empty.');
        }

        // Save CSS content
        console.log('Sending PUT request to /pageCSS with:', {
          pageId: pageIdToSave,
          pageName: pageToSave.pageName,
          cssContent,
        });

        // Save CSS content
        const cssResponse = await axios.put(
          'https://www.webbify.io/pageCSS',
          {
            pageId: pageIdToSave,
            pageName: pageToSave.pageName,
            cssContent,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('CSS Save Response:', cssResponse.data);

        // After saving, update the pages state to reflect the saved data.
        const updatedPages = [...pages];
        const pageIndex = updatedPages.findIndex(page => page.pageId === pageIdToSave);
        if (pageIndex !== -1) {
          updatedPages[pageIndex] = {
            ...updatedPages[pageIndex],
            content: (
              <PageContainer 
                components={components}
                designTokens={designTokens}
                rows={rows}
                selectedComponentInfo={selectedComponentInfo} 
                setSelectedComponentInfo={setSelectedComponentInfo}
                editedTokens={editedTokens} 
                setEditedTokens={setEditedTokens}
                nestedComponents={nestedComponents}
              />
            ),
          };
          setPages(updatedPages);
        }
      } catch (error) {
        console.error('Error saving page content:', error);
      }
    }, [token, pages, setPages, activePage, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens, nestedComponents, rows]);



    return savePageContent;
};

export default useSavePageContent;