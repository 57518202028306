import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Spin, Progress, Button } from 'antd';
import html2canvas from 'html2canvas';
import axios from 'axios';

const ComponentWindow = ({ componentName }) => {
  const [containerEl] = useState(document.createElement('div'));
  const newWindow = useRef(null);
  const [loadAttempts, setLoadAttempts] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [componentLoaded, setComponentLoaded] = useState(false);

  useEffect(() => {
    console.log(`Opening new window for component: ${componentName}`);
    newWindow.current = window.open('', '', 'width=300,height=300,left=200,top=200');
    if (newWindow.current) {
      newWindow.current.document.body.appendChild(containerEl);
    } else {
      console.error('Failed to open new window');
    }

    return () => {
      if (newWindow.current) {
        newWindow.current.close();
        console.log(`Closed window for component: ${componentName}`);
      }
    };
  }, [containerEl, componentName]);

  useEffect(() => {
    if (loading && loadAttempts < 3 && !error) {
      const timer = setTimeout(() => {
        setLoadAttempts((prev) => prev + 1);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [loadAttempts, error, loading]);

  const handleError = (error) => {
    console.error(`Error loading component: ${componentName}`, error);
    setError(error);
    setLoading(false);
  };

  useEffect(() => {
    if (componentLoaded) {
      adjustWindowSize();
      const timeoutId = setTimeout(() => {
        if (newWindow.current && !newWindow.current.closed) {
          takeScreenshot();
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [componentLoaded]);

  const adjustWindowSize = () => {
    if (newWindow.current && containerEl.firstChild) {
      const { width, height } = containerEl.firstChild.getBoundingClientRect();
      const size = Math.max(width, height);
      newWindow.current.resizeTo(size + 40, size + 40);
      newWindow.current.focus();
    }
  };

  const handleLoad = () => {
    setLoading(false);
    setComponentLoaded(true);
    //setTimeout(takeScreenshot, 1000); // Take screenshot once component is loaded
  };

  const takeScreenshot = () => {
    if (newWindow.current && !newWindow.current.closed && containerEl.firstChild) {
      html2canvas(containerEl.firstChild, {
        backgroundColor: null,
        logging: true,
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        console.log('Screenshot taken:', imgData);

        axios.post('/api/save-screenshot', { componentName, imgData })
          .then(response => {
            console.log('Screenshot saved successfully:', response.data);
          })
          .catch(error => {
            console.error('Error saving screenshot:', error.response ? error.response.data : error.message);
          });
      });
    }
  };

  if (!newWindow.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <React.Suspense fallback={<Spin tip="Loading..." />}>
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ComponentLoader componentName={componentName} onLoad={handleLoad} onError={setError} />
      </div>
    </React.Suspense>,
    containerEl
  );
};

const ComponentLoader = ({ componentName, onLoad, onError }) => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    console.log(`Loading component: ${componentName}`);
    import(`./ui_components/${componentName}/${componentName}`)
      .then((module) => {
        setComponent(() => module.default);
        onLoad();
      })
      .catch((error) => {
        onError(error);
      });
  }, [componentName, onLoad, onError]);

  return Component ? <div><Component /></div> : <div>Loading component...</div>;
};

export default ComponentWindow;
