// frontend/src/components/UserComponentImports.js
import axios from 'axios';

// Function to dynamically import components by their stored code
export const loadComponent = async (componentName, componentCode) => {
  const module = await new Function(componentCode)();
  return module.default;
};

export const fetchUserComponents = async (token) => {
  try {
    const response = await axios.get('https://www.webbify.io/components', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.map(component => ({
      name: component.name,
      load: () => loadComponent(component.name, component.code)
    }));
  } catch (error) {
    console.error('Failed to fetch user components:', error);
    return [];
  }
};
