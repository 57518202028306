// frontend/src/components/MainFooter.js
import React from 'react';
import '../App.css'; // Assuming general styles are here
import { ReactComponent as Logo } from '../assets/webbify-logo2.svg'; 

const MainFooter = () => {
  return (
    <div className="main-footer">
      <div className="footer-top">
        <h2>Build web sites<br/>like never before.</h2>
        <p>Built for product teams to identify, design and build, test and release all in one place, simply log in and you’re ready to start.</p>
        <div className="footer-buttons">
          <button>Try it for free</button>
          <button>Learn More</button>
        </div>
      </div>
      <div className="footer-links">
        <div className="links-row">
          <div className="link-column">
            <strong>Resources</strong>
            <a href="#">FAQ</a>
            <a href="#">Support</a>
            <a href="#">Documentation</a>
          </div>
          <div className="link-column">
            <strong>Company</strong>
            <a href="#">About Us</a>
            <a href="#">Careers</a>
            <a href="#">Contact</a>
          </div>
          <div className="link-column">
            <strong>Legal</strong>
            <a href="#">Terms of Service</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Cookie Policy</a>
          </div>
          <div className="link-column">
            <strong>Follow Us</strong>
            <a href="#">Twitter</a>
            <a href="#">Facebook</a>
            <a href="#">LinkedIn</a>
          </div>
        </div>
        <div className="links-row bottom">
          <p>© 2024 Webbify Ltd. All rights reserved.</p>
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
