// frontend/src/components/properties-sidebar/DimensionsAndSpacing.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  SideTop,
  SideRight,
  SideBottom,
  SideLeft,
  SizeHeight,
  SizeWidth,
  PaddingTop,
  PaddingRight,
  PaddingBottom,
  PaddingLeft,
} from '../../assets/icons/properties-sidebar/icons';
import { getRelevantTokens } from './utils';

const DimensionsSpacingOptions = ({
  selectedComponentInfo,
  globalTokens,
  editedTokens,
  designTokens,
  tokenNames,
  handleTokenChange,
  setEditedTokens,
  setTokenNames,
  viewportSize,
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const componentId = selectedComponentInfo.id;

  // State to manage local input without triggering re-render immediately
  const [localValues, setLocalValues] = useState({});

  // Effect to initialize localValues with existing tokens or props
  useEffect(() => {
    const initialValues = {};
    const componentTokens =
      editedTokens[componentId]?.[viewportSize] ||
      designTokens[componentId]?.[viewportSize] ||
      (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
      {};

    console.log('DimensionsSpacingOptions useEffect: Loading initial tokens for component:', componentId);
    console.log('Edited Tokens:', editedTokens[componentId]?.[viewportSize]);
    console.log('Design Tokens:', designTokens[componentId]?.[viewportSize]);
    console.log(`Selected Component Tokens for viewport "${viewportSize}":`, selectedComponentInfo.tokens?.[viewportSize]);

    // Set initial values for each token property type
    Object.keys(componentTokens).forEach((propertyType) => {
      initialValues[propertyType] = componentTokens[propertyType];
      console.log(`Setting initial value for ${propertyType}:`, componentTokens[propertyType]);
    });

    setLocalValues(initialValues);
    console.log('DimensionsSpacingOptions: localValues set to:', initialValues);
  }, [componentId, viewportSize, editedTokens, designTokens, selectedComponentInfo.tokens]);

  const labelInputDropdown = (label, icon, propertyType) => {
    // Prioritize editedTokens, fallback to designTokens
    const inputValue =
      localValues[propertyType] ||
      editedTokens[componentId]?.[viewportSize]?.[propertyType] ||
      designTokens[componentId]?.[viewportSize]?.[propertyType] ||
      selectedComponentInfo.tokens?.[propertyType] ||
      '';

    const tokenName = tokenNames[componentId]?.[propertyType] || '';
    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    console.log(`Rendering input for ${propertyType}: inputValue="${inputValue}", tokenName="${tokenName}"`);

    const menu = (
      <Menu
        onClick={(e) => {
          const tokenValue = relevantTokens[e.key];
          console.log(`Selected token from dropdown for ${propertyType}:`, tokenValue);
          handleTokenChange(propertyType, tokenValue);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [componentId]: {
              ...(prevTokens[componentId] || {}),
              [viewportSize]: {
                ...(prevTokens[componentId]?.[viewportSize] || {}),
                [propertyType]: tokenValue,
              },
            },
          }));
          // Update tokenNames to store the selected token name
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [componentId]: {
              ...prevTokenNames[componentId],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item
            key={key}
            onClick={() => console.log(`Menu.Item clicked: ${key} - ${value}`)}
          >
            {key} - {value}
          </Menu.Item>
        ))}
      </Menu>
    );

    const handleBlurOrEnter = (event, propertyType) => {
      if (event.type === 'blur' || event.key === 'Enter') {
        const newValue = localValues[propertyType] || inputValue;
        console.log(`Blur or Enter detected for ${propertyType}, setting value: "${newValue}"`);
        handleTokenChange(propertyType, newValue);
      }
    };

    return (
      <div
        className="prop-sidebar-item"
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        <label alt={label}>{icon}</label>
        <Input
          type="text"
          value={localValues[propertyType] || tokenName || inputValue}
          placeholder={propertyType}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocalValues((prev) => ({
              ...prev,
              [propertyType]: newValue,
            }));
          }}
          onBlur={(e) => handleBlurOrEnter(e, propertyType)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleBlurOrEnter(e, propertyType);
            }
          }}
        />
        {Object.keys(relevantTokens).length > 0 && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ cursor: 'pointer' }}>
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('width', <SizeWidth />, 'width')}</Col>
        <Col span={12}>{labelInputDropdown('height', <SizeHeight />, 'height')}</Col>
      </Row>
      <label>Margins</label>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('margin-top', <SideTop />, 'marginTop')}</Col>
        <Col span={12}>{labelInputDropdown('margin-right', <SideRight />, 'marginRight')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('margin-bottom', <SideBottom />, 'marginBottom')}</Col>
        <Col span={12}>{labelInputDropdown('margin-left', <SideLeft />, 'marginLeft')}</Col>
      </Row>
      <label>Padding</label>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('padding-top', <PaddingTop />, 'paddingTop')}</Col>
        <Col span={12}>{labelInputDropdown('padding-right', <PaddingRight />, 'paddingRight')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('padding-bottom', <PaddingBottom />, 'paddingBottom')}</Col>
        <Col span={12}>{labelInputDropdown('padding-left', <PaddingLeft />, 'paddingLeft')}</Col>
      </Row>
    </>
  );
};

export default DimensionsSpacingOptions;
