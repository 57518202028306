// frontend/src/utils/fetchPageContentUtils.js
import axios from 'axios';
import { reconstructFrontendStructureFromBackendSchema } from './reconstructFrontendStructureFromBackendSchema';
import PageContainer from '../components/PageContainer';

export const fetchPageContent = async (token, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens) => {
  if (!token) {
    console.error('No token available.');
    return null;
  }

  console.log('fetchPageContent - Fetching Page Content with Token:', token);

  try {
    const pageContentResponse = await axios.get('https://www.webbify.io/pageContent', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await Promise.all(pageContentResponse.data.map(async pageData => {
      if (!pageData.content || !Array.isArray(pageData.content)) {
        console.error(`Invalid or missing content for pageData: ${pageData.pageId}`);
        return null;
      }

      //const pageRows = await reconstructFrontendStructureFromBackendSchema(pageData.content, components, nestedComponents, token);
      const { rows: pageRows, nestedComponents } = await reconstructFrontendStructureFromBackendSchema(
        pageData.content,
        components,
        null,
        token
      );

      return {
        pageId: pageData.pageId,
        pageName: pageData.pageName || 'Unnamed Page',
        content: (
          <PageContainer
            components={components}
            designTokens={designTokens}
            rows={pageRows}
            //selectedComponentInfo={selectedComponentInfo}
            //setSelectedComponentInfo={setSelectedComponentInfo}
            editedTokens={editedTokens}
            setEditedTokens={setEditedTokens}
            nestedComponents={nestedComponents}
          />
        ),
      };
    }));

  } catch (error) {
    console.error('Error fetching page content:', error);
    return null;
  }
};