// frontend/src/components/ui_components/Container/Container.jsx
import React, { useContext } from 'react';
import { BuilderContext } from '../../../contexts/BuilderContext';
import { NestedComponentsContext } from '../../../contexts/NestedComponentsContext';
import { TokensContext } from '../../../contexts/TokensContext';
import { ComponentPropsContext } from '../../../contexts/ComponentPropsContext';
import { ViewportContext } from '../../../contexts/ViewportContext';
import useNestedComponentDrop from '../../../hooks/useNestedComponentDrop';
import useComponentDrop from '../../../hooks/useComponentDrop';
import { getComponentProps } from '../../../hooks/getComponentProps';

const Container = ({ id, children, handleComponentClick, tokens, backgroundImage, ...props }) => {
  const isBuilder = useContext(BuilderContext);
  const viewportSize = useContext(ViewportContext);

  const { nestedComponents } = useContext(NestedComponentsContext);
  const { designTokens, editedTokens } = useContext(TokensContext);
  const { selectedComponentProps, updateComponentProps } = useContext(ComponentPropsContext);
  const nestedComponentsForContainer = nestedComponents[id] || {};

  const token = localStorage.getItem('token') || '';
  const handleNestedComponentDrop = useNestedComponentDrop(id, token, designTokens, editedTokens);
  const handleGeneralDrop = useComponentDrop();
  console.log(`Container Component (${id}): Retrieved nestedComponents:`, nestedComponentsForContainer);

  // Default tokens
  const defaultTokens = {
    flexGrow: '1',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  };

  const mergedTokens = {
    ...defaultTokens,
    ...tokens,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundSize: 'cover',
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const onDropHandler = (event) => {
    if (isBuilder) {
      // Determine if the drop target is a Container or Column based on `event.target`
      if (event.target.classList.contains('container-component')) {
        handleNestedComponentDrop(event); // Use nested drop handler for Container
      } else {
        handleGeneralDrop(event); // Use general drop handler for other components
      }
    }
  };

  const renderNestedComponents = () => {
    if (!isBuilder && children) {
      return children; // In public mode, render children passed to the component
    }

    // In builder mode, use nestedComponents from the context
    return Object.keys(nestedComponentsForContainer).map((columnId) => {
      const componentsArray = nestedComponentsForContainer[columnId];

      return Array.isArray(componentsArray)
        ? componentsArray.map((component) => {
            if (!component.id) {
              console.error('Container: Nested component missing ID:', component);
              return null;
            }

            const Component = component.component;
              if (!Component) {
                console.error('Component is undefined for:', component.name);
                return null;
              }

            // Compute tokens for nested component using viewportSize
            const nestedTokens = {
              ...designTokens[component.id]?.[viewportSize],
              ...editedTokens[component.id]?.[viewportSize],
              ...component.tokens?.[viewportSize],
            };

            // Merge props correctly: defaultProps, component.props, selectedComponentProps
            const componentProps = {
              ...getComponentProps(Component),
              ...component.props,
              ...selectedComponentProps[component.id],
            };

            // Update selectedComponentProps if not already set
            if (!selectedComponentProps[component.id]) {
              updateComponentProps(component.id, componentProps);
            }

            console.log(`Rendering nested component: ${component.name} with ID: ${component.id}`);

            // Check if the component is a Container
            if (component.name === 'Container') {
              return (
                <div
                  key={component.id}
                  id={component.id}
                  className="nested-container"
                  data-component-name={component.name}
                  data-component-source={component.source}
                  data-s3-component-name={component.s3ComponentName || ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleComponentClick(component);
                  }}
                  onDrop={onDropHandler}
                  onDragOver={allowDrop}
                  style={{
                    width: '100%',
                    height: 'auto',
                    flex: '1 1 auto',
                    margin: '10px',
                    zIndex: 10,
                    position: 'relative',
                    cursor: 'pointer',
                    pointerEvents: 'all',
                    boxSizing: 'border-box',
                  }}
                >
                  <Container
                    id={component.id}
                    {...componentProps}
                    tokens={nestedTokens}
                    handleComponentClick={handleComponentClick}
                  >
                    {/* Recursive Rendering */}
                  </Container>
                </div>
              );
            }

            return (
              <div
                key={component.id}
                id={component.id}
                style={{
                  width: 'auto',
                  height: 'auto',
                  margin: '0px',
                  zIndex: 10,
                  position: 'relative',
                  cursor: 'pointer',
                  pointerEvents: 'all',
                  boxSizing: 'border-box',
                }}
                className={component.name}
                data-component-name={component.name}
                data-component-source={component.source}
                data-s3-component-name={component.s3ComponentName || ''}
                onClick={(e) => {
                  e.stopPropagation();
                  handleComponentClick(component);
                }}
                onDrop={onDropHandler}
                onDragOver={allowDrop}
              >
                <Component
                  id={component.id}
                  {...componentProps}
                  tokens={nestedTokens}
                  handleComponentClick={handleComponentClick}
                />
              </div>
            );
          })
        : null;
    });
  };

  return (
    <div
      style={{
        ...mergedTokens,
        ...props.style,
        boxSizing: 'border-box',
        zIndex: 1,
      }}
      className="container-component"
      onClick={(e) => {
        handleComponentClick({ id, name: 'Container' }); // Select the Container itself when clicking on its empty space
      }}
      onDrop={onDropHandler}
      onDragOver={allowDrop}
    >
      {renderNestedComponents()}
    </div>
  );
};

// Set default props for Container
Container.defaultProps = {
  backgroundImage: '', // Default is no background image
};

export default Container;
