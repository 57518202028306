import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import './MediaSidebar.css';

const MediaSidebar = ({ onFolderSelect }) => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null); // Track selected folder or subfolder

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get('/media/list-folders', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFolders(response.data.folders);
      } catch (error) {
        console.error('Error fetching folders:', error);
        message.error('Failed to fetch folders.');
      } finally {
        setLoading(false);
      }
    };

    fetchFolders();
  }, []);

  // Handle folder or subfolder selection and update active state
  const handleSelect = (folderPath) => {
    setSelectedFolder(folderPath); // Set the active folder or subfolder
    onFolderSelect(folderPath ? `${folderPath}/` : null);
  };

  return (
    <div className="mediaSidebar">
      <div className="mediaSidebarHeading">
        <h4>Folders</h4>
      </div>
      
      {/* All Files link with active class */}
      <div
        className={`all-files ${selectedFolder === null ? 'active' : ''}`}
        onClick={() => handleSelect(null)}
      >
        <strong>All Files</strong>
      </div>

      {loading ? (
        <p>Loading folders...</p>
      ) : (
        folders.map((folder) => (
          <div key={folder} className="main-folder">
            <strong>{folder}</strong>
            <div className="sub-folder-list">
              {['Images', 'Icons', 'Video', 'Documents', 'Music'].map((subFolder) => (
                <div
                  key={`${folder}/${subFolder}`}
                  className={`sub-folder ${selectedFolder === `${folder}/${subFolder}` ? 'active' : ''}`}
                  onClick={() => handleSelect(`${folder}/${subFolder}`)}
                >
                  {subFolder}
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MediaSidebar;
